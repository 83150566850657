'use client'

import { trimmedString } from '@qogita/validation/string'
import Script from 'next/script'
import { useConsent } from 'src/core/consent'
const NEXT_PUBLIC_BING_TAG_ID = trimmedString().parse(
  process.env.NEXT_PUBLIC_BING_TAG_ID,
)

export function BingScript() {
  const { hasConsent } = useConsent()

  if (!hasConsent('marketing')) {
    return null
  }

  return (
    <Script id="bing-tag" strategy="afterInteractive">
      {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${NEXT_PUBLIC_BING_TAG_ID}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
    </Script>
  )
}
