'use client'

import clsx from 'clsx'
import { ReactNode } from 'react'

import { useIsActiveContext } from './context'

type IconProps = {
  children: ReactNode
}

export function NavigationIcon({ children }: IconProps) {
  const isActive = useIsActiveContext()
  return (
    <div
      className={clsx('mr-3 w-[1.125rem] shrink-0', {
        'text-gray-400': !isActive,
        'text-primary-700': isActive,
      })}
      aria-hidden="true"
    >
      {children}
    </div>
  )
}
