import { createContext, useContext } from 'react'
import {
  LoqateAddress,
  LoqatePredictionItem,
  LoqateResponse,
} from 'src/deprecated/types/index'

const baseUrl = 'https://api.addressy.com/Capture/Interactive'

export class LoqateClient {
  private baseParams: URLSearchParams

  constructor(private apiKey: string) {
    this.baseParams = new URLSearchParams()
    this.baseParams.set('key', apiKey)
  }

  private async fetch<T extends Record<string, unknown>>(resource: string) {
    const response = await fetch(resource)
    const { Items } = (await response.json()) as LoqateResponse<T>

    // https://marcinbiernat.pl/2020/02/Type-safe-filters/
    const errors = Items.flatMap((item) => ('Error' in item ? [item] : []))
    if (errors.length) return Promise.reject({ Items: errors })

    const validItems = Items.flatMap((item) => ('Error' in item ? [] : [item]))

    return { Items: validItems }
  }

  async find({
    text,
    countryCode,
    container,
    limit = 2,
  }: {
    text: string
    countryCode: string
    container?: string | null
    limit?: number
  }) {
    const endpoint = 'Find/v1.10/json3.ws'
    const params = new URLSearchParams(this.baseParams)
    params.set('Text', text)
    params.set('Countries', countryCode)
    params.set('Limit', String(limit))

    if (container) {
      params.set('Container', container)
    }

    return this.fetch<LoqatePredictionItem>(
      `${baseUrl}/${endpoint}?${params.toString()}`,
    )
  }

  async retrieve({ id }: { id: string }) {
    const endpoint = 'Retrieve/v1.20/json3.ws'
    const params = new URLSearchParams(this.baseParams)
    params.set('Id', id)

    return this.fetch<LoqateAddress>(
      `${baseUrl}/${endpoint}?${params.toString()}`,
    )
  }
}

const LoqateClientContext = createContext<LoqateClient | null>(null)
export function useLoqateClient() {
  const client = useContext(LoqateClientContext)
  if (!client) {
    throw new Error(
      'useLoqateClient must be used within a LoqateClientProvider',
    )
  }
  return client
}

export function LoqateClientProvider({
  apiKey,
  children,
}: {
  apiKey: string
  children: React.ReactNode
}) {
  return (
    <LoqateClientContext.Provider value={new LoqateClient(apiKey)}>
      {children}
    </LoqateClientContext.Provider>
  )
}
