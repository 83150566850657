'use client'

import type { ReactNode } from 'react'
import { useState } from 'react'

import {
  IsActiveContext,
  IsCollapsedContext,
  NavigationDepthContext,
  SetIsCollapsedContext,
  useNavigationDepth,
} from './context'

type SectionProps = {
  children: ReactNode
  isActive: boolean
}

export function NavigationSection({ children, isActive }: SectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(!isActive)

  const navigationDepth = useNavigationDepth()

  return (
    <NavigationDepthContext.Provider value={navigationDepth + 1}>
      <IsCollapsedContext.Provider value={isCollapsed}>
        <IsActiveContext.Provider value={isActive}>
          <SetIsCollapsedContext.Provider value={setIsCollapsed}>
            {children}
          </SetIsCollapsedContext.Provider>
        </IsActiveContext.Provider>
      </IsCollapsedContext.Provider>
    </NavigationDepthContext.Provider>
  )
}
