import { cn } from '@qogita/ui/utils/cn'
import { ComponentProps } from 'react'

export function Icon({
  viewBox = '0 0 24 24',
  className,
  title,
  children,
  ...props
}: ComponentProps<'svg'> & { title?: string }) {
  return (
    <svg
      className={cn('size-6', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      aria-hidden
      {...props}
    >
      {title ? <title>{title}</title> : null}
      {children}
    </svg>
  )
}
