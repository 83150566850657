import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import clsx from 'clsx'
import { LegalPanelStoryblok } from 'src/core/cms/storyblok-component-types'

import { getTOCHeadings, renderRichText } from './RichText'
import { renderTOC } from './RichText'

export const LegalPanel = ({ blok }: { blok: LegalPanelStoryblok }) => {
  const header = blok.header[0]!

  const headings = getTOCHeadings({ docs: blok.content })
  const hasHeadings = headings.length > 0

  return (
    <div className="container mx-auto grid gap-2 px-2 py-2 sm:px-4">
      <div
        className="rounded bg-white px-4 py-8 text-center shadow lg:py-12"
        {...storyblokEditable(header)}
      >
        <h1 className="mb-4 text-3xl font-semibold sm:text-4xl lg:text-5xl">
          {header.heading}
        </h1>
        {header.description ? renderRichText(header.description) : null}
      </div>
      <div
        className={clsx('relative grid grid-cols-1 gap-2', {
          'md:grid-cols-3': hasHeadings,
        })}
      >
        {hasHeadings ? (
          <div className="col-span-full rounded bg-white px-4 py-8 shadow sm:p-8 md:p-12 lg:sticky lg:top-0 lg:col-span-1 lg:h-screen lg:self-start lg:overflow-scroll xl:p-16">
            <strong className="mb-4 block text-sm font-bold uppercase opacity-75">
              Sections
            </strong>
            {blok.content.map((blok) => (
              <div key={blok._uid} className="flex flex-col gap-1">
                {renderTOC(headings)}
              </div>
            ))}
          </div>
        ) : null}
        <div className="col-span-full row-start-2 rounded bg-white px-4 py-8 shadow sm:p-8 md:p-12 lg:col-span-2 lg:row-start-1 xl:p-16">
          <div
            className={clsx('max-w-prose', {
              'mx-auto': hasHeadings,
            })}
          >
            {blok.content.map((content) => (
              <div key={content._uid}>
                <StoryblokComponent blok={content} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
