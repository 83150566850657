'use client'

import { Slot } from '@radix-ui/react-slot'
import { VariantProps } from 'class-variance-authority'
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'

import { cn } from '../Utils/cn'
import { buttonStyles } from './Button'

type AnchorProps = ComponentPropsWithoutRef<'a'> &
  VariantProps<typeof buttonStyles> & {
    variant?: never
    startIcon?: never
    asChild?: boolean
  }

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(function Anchor(
  {
    color = 'primary',
    appearance = 'text',
    size,
    className,
    asChild,
    ...props
  },
  ref,
) {
  const Component = asChild ? Slot : 'a'

  return (
    <Component
      className={cn(buttonStyles({ color, appearance, size }), className)}
      ref={ref}
      {...props}
    />
  )
})

function Icon({ children }: { children: ReactNode }) {
  return <span className="size-4.5 block shrink-0">{children}</span>
}

const AnchorRoot = Object.assign(Anchor, { Icon })

export { AnchorRoot as Anchor }
