import {
  CanaryClient,
  GetCartDroppedLinesSearchParams,
  GetCartLinesSearchParams,
  GetCartsSearchParams,
} from '@qogita/canary-client'
import { useCanaryClient } from '@qogita/canary-client/provider'
import {
  CartAllocationLineRequest,
  CartLineRequest,
  PatchedCartAllocationLineRequest,
  PatchedCartLinePatchRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useAddToCartToast } from 'src/deprecated/components/AddToCartToast'
import {
  UploadFile,
  uploadFileToStorage,
} from 'src/deprecated/hooks/useUploadFilesToStorage'
import { EventOrigin, useTrackEvent } from 'src/deprecated/lib/report/tracking'

import { getCheckoutQueries } from './checkout-queries'
import { replaceUndefinedValuesWithNull } from './utilities'

export function getCartQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['carts'] as const,
    allLists: () => [...queries.all(), 'list'] as const,
    list: (params: GetCartsSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLists(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCarts(params),
      }),

    activeDetail: () =>
      queryOptions({
        queryKey: [...queries.all(), 'detail'] as const,
        queryFn: () => canaryClient.getActiveCart(),
      }),
    allLines: () => [...queries.activeDetail().queryKey, 'lines'] as const,
    linesList: (params: GetCartLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getActiveCartLines(params),
      }),
    lineDetail: ({ lineQid }: { lineQid: string }) =>
      [...queries.allLines(), 'detail', lineQid] as const,
    allDroppedLines: () =>
      [...queries.activeDetail().queryKey, 'droppedLines'] as const,
    droppedLinesList: (params: GetCartDroppedLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allDroppedLines(),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getActiveCartDroppedLines(params),
      }),
    allAllocations: () =>
      [...queries.activeDetail().queryKey, 'allocations'] as const,
    allocationsList: () =>
      queryOptions({
        queryKey: [...queries.allAllocations(), 'allocations'] as const,
        queryFn: () => canaryClient.getActiveCartAllocations(),
      }),
    allocationDetail: ({ allocationQid }: { allocationQid: string }) =>
      [...queries.allAllocations(), 'detail', allocationQid] as const,
    allAllocationLines: ({ allocationQid }: { allocationQid: string }) =>
      [...queries.allocationDetail({ allocationQid }), 'lines'] as const,
    allocationLineDetail: ({
      allocationQid,
      lineQid,
    }: {
      allocationQid: string
      lineQid: string
    }) =>
      [
        ...queries.allAllocationLines({ allocationQid }),
        'detail',
        lineQid,
      ] as const,
  }
  return queries
}

export function useCreateCartLine({
  cartQid,
  gtin,
  offerQid,
  pricingModel,
}:
  | {
      cartQid: string
      gtin: string
      offerQid?: undefined
      pricingModel: 'Optimizer Based'
    }
  | {
      cartQid: string
      gtin?: undefined
      offerQid: string
      pricingModel: 'Offer Based'
    }) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const { trackProductAdded } = useTrackEvent()
  const { toast } = useAddToCartToast()

  return useMutation({
    mutationKey: [...cartQueries.allLines(), 'create'] as const,
    mutationFn: (data: Omit<CartLineRequest, 'gtin' | 'offerQid'>) => {
      if (gtin) {
        return canaryClient.createCartLine({
          data: { ...data, gtin },
        })
      } else {
        return canaryClient.createCartLine({
          data: { ...data, dealId: undefined, offerQid },
        })
      }
    },
    onSuccess: async (cartLine, request) => {
      trackProductAdded({
        cartId: cartQid,
        quantity: request.quantity,
        product: {
          brand: cartLine.variant.brand.name,
          category: cartLine.variant.category.name,
          currency: cartLine.priceCurrency,
          name: cartLine.variant.name,
          price: cartLine.price,
          productId: cartLine.variant.gtin,
        },
      })
      await queryClient.invalidateQueries()
      toast({
        variant: cartLine.variant,
        quantity: request.quantity,
        isDeal: request.dealId !== undefined,
        price: cartLine.price,
        priceCurrency: cartLine.priceCurrency,
        pricingModel,
      })
    },
  })
}

export function useUpdateCartLine({ lineQid }: { lineQid: string }) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [...cartQueries.lineDetail({ lineQid }), 'update'] as const,
    mutationFn: (data: PatchedCartLinePatchRequest) =>
      canaryClient.updateCartLine({ lineQid, data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useDeleteCartLine({ lineQid }: { lineQid: string }) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [...cartQueries.lineDetail({ lineQid }), 'delete'] as const,
    mutationFn: () => canaryClient.deleteCartLine({ lineQid }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateCartAllocationLine({
  cartQid,
  allocationQid = '',
  gtin,
}: {
  cartQid: string
  allocationQid?: string
  gtin: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const { trackProductAdded } = useTrackEvent()
  const { toast } = useAddToCartToast()

  return useMutation({
    mutationKey: [
      ...cartQueries.allAllocationLines({ allocationQid }),
      'create',
    ] as const,
    mutationFn: (data: Omit<CartAllocationLineRequest, 'gtin'>) => {
      if (!allocationQid) {
        throw new Error(
          `Tried to add to an allocation without an allocationQid`,
        )
      }
      return canaryClient.createCartAllocationLine({
        allocationQid,
        data: { ...data, gtin },
      })
    },
    onSuccess: async ({ allocationLine }, request) => {
      trackProductAdded({
        cartId: cartQid,
        quantity: request.quantity,
        product: {
          brand: allocationLine.variant.brand.name,
          category: allocationLine.variant.category.name,
          currency: allocationLine.priceCurrency,
          name: allocationLine.variant.name,
          price: allocationLine.price,
          productId: allocationLine.variant.gtin,
        },
      })
      await queryClient.invalidateQueries()
      toast({
        variant: allocationLine.variant,
        quantity: request.quantity,
        isDeal: request.dealId !== undefined,
        price: allocationLine.price,
        priceCurrency: allocationLine.priceCurrency,
        pricingModel: 'Optimizer Based', // create cart allocation line is only used for optimizer based pricing models
      })
    },
  })
}

export function useUpdateCartAllocationLine({
  allocationQid,
  lineQid,
}: {
  allocationQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.allocationLineDetail({ allocationQid, lineQid }),
      'update',
    ] as const,
    mutationFn: (data: PatchedCartAllocationLineRequest) =>
      canaryClient.updateCartAllocationLine({
        allocationQid,
        lineQid,
        data,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useDeleteCartAllocationLine({
  allocationQid,
  lineQid,
}: {
  allocationQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.allocationLineDetail({ allocationQid, lineQid }),
      'delete',
    ] as const,
    mutationFn: () =>
      canaryClient.deleteCartAllocationLine({
        allocationQid,
        lineQid,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useOptimizeCartMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const checkoutQueries = getCheckoutQueries(canaryClient)

  return useMutation({
    mutationKey: [...cartQueries.activeDetail().queryKey, 'optimize'] as const,
    mutationFn: () =>
      canaryClient.optimizeCart({ optimizerStrategy: 'FIXED_PRICE' }),
    onSuccess: async (checkout) => {
      queryClient.setQueryData(checkoutQueries.detail().queryKey, checkout)
      await queryClient.invalidateQueries()
    },
  })
}

export function useEmptyCart() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationFn: () => canaryClient.emptyActiveCart(),
    onSuccess: async (updatedCart) => {
      queryClient.setQueryData(cartQueries.activeDetail().queryKey, updatedCart)
      await queryClient.invalidateQueries()
    },
  })
}

export function useUploadCart({ origin }: { origin: EventOrigin }) {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()
  const cartQueries = getCartQueries(canaryClient)

  return useMutation({
    mutationFn: async (file: UploadFile) => {
      const fileKey = await uploadFileToStorage(file)
      return canaryClient.uploadCart({ fileKey, origin })
    },
    onSuccess: async (cart) => {
      queryClient.setQueryData(cartQueries.activeDetail().queryKey, cart)
      await queryClient.invalidateQueries()
    },
  })
}

/**
 * Returns true if any kind of mutation is having an effect on the cart
 */
export function useIsCartMutating() {
  const canaryClient = useCanaryClient()
  const cartQueries = getCartQueries(canaryClient)

  return (
    useIsMutating({
      mutationKey: cartQueries.activeDetail().queryKey,
    }) > 0
  )
}
