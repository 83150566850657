'use client'

import { createContext, ReactNode, useContext } from 'react'

/**
 * values that the client is allowed to know about authentication
 */
type ClientAuth =
  | { isAuthenticated: false }
  | { isAuthenticated: true; signature: string }

const AuthenticationContext = createContext<ClientAuth | undefined>(undefined)

export function AuthenticationProvider({
  children,
  ...clientAuthProps
}: { children: ReactNode } & ClientAuth) {
  return (
    <AuthenticationContext.Provider value={{ ...clientAuthProps }}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationProvider.',
    )
  }
  return context
}
