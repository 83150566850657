import { storyblokEditable } from '@storyblok/react'
import { ComponentPropsWithoutRef } from 'react'
import { CtaStoryblok } from 'src/core/cms/storyblok-component-types'
import { NextAnchor } from 'src/deprecated/components/NextAnchor'

export const Anchor = ({
  blok,
  className,
  color,
  appearance,
  size,
  children,
}: {
  blok: CtaStoryblok
  className?: string
  children?: React.ReactNode
} & Pick<
  ComponentPropsWithoutRef<typeof NextAnchor>,
  'color' | 'size' | 'appearance'
>) => {
  return (
    <NextAnchor
      {...storyblokEditable(blok)}
      target={blok.link.target}
      // If the link is an `internal` link in Storyblok it'll have a nested story, otherwise if external we can use the bare `url`
      // https://www.storyblok.com/docs/guide/in-depth/rendering-the-link-field
      href={blok.link.story?.url ?? blok.link.url}
      color={color}
      size={size}
      appearance={appearance}
      className={className}
    >
      {blok.label}
      {children}
    </NextAnchor>
  )
}

Anchor.Icon = NextAnchor.Icon
