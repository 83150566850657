import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { isDatadogInitialized } from './datadog'
import { Queue } from './queue'

if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
  throw new Error('NEXT_PUBLIC_VERCEL_ENV is not defined')
}

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message
  }
  if (typeof error === 'string') {
    return error
  }

  return 'Unable to determine error message'
}

const errorQueue = new Queue<{
  error: Error
  context?: Record<string, unknown>
}>()

const getErrorInstance = (error: unknown) => {
  if (error instanceof Error) {
    return error
  }
  if (typeof error === 'string') {
    return new Error(error)
  }
  // This should never happen, but because any value is throwable in JS,
  // we should handle unkonwn types to make TypeScript happy
  return new Error('Unknown error', {
    cause: error,
  })
}

export const logError = (error: unknown, context?: Record<string, unknown>) => {
  const errorInstance = getErrorInstance(error)

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      if (isDatadogInitialized) {
        datadogRum.addError(errorInstance, { context })
      } else {
        errorQueue.enqueue({ error: errorInstance, context })
      }
      break

    default:
      console.error(error, JSON.stringify(context, null, 2))
  }
}

export const flushErrorQueue = () => {
  errorQueue.flush(({ error, context }) => {
    datadogRum.addError(error, { context })
  })
}

const warningQueue = new Queue<{
  errorMessage: string
  context?: Record<string, unknown>
  errorInstance?: Error
}>()

export const logWarning = (
  error: unknown,
  context?: Record<string, unknown>,
) => {
  const errorMessage = getErrorMessage(error)
  const errorInstance = error instanceof Error ? error : undefined

  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      if (isDatadogInitialized) {
        datadogLogs.logger.warn(errorMessage, context, errorInstance)
      } else {
        warningQueue.enqueue({ errorMessage, context, errorInstance })
      }
      break

    case 'preview':
      if (isDatadogInitialized) {
        datadogLogs.logger.warn(errorMessage, context, errorInstance)
      } else {
        warningQueue.enqueue({ errorMessage, context, errorInstance })
      }
      console.warn(error, JSON.stringify(context, null, 2))
      break

    default:
      console.warn(error, JSON.stringify(context, null, 2))
  }
}

export const flushWarningQueue = () => {
  warningQueue.flush(({ errorMessage, context, errorInstance }) => {
    datadogLogs.logger.warn(errorMessage, context, errorInstance)
  })
}
