import { logError } from '@qogita/logging/browser-logger'

export const bingEvent = (
  eventName: string,
  eventParams?: Record<string, unknown>,
) => {
  if (!window.uetq) return

  try {
    window.uetq.push('event', eventName, eventParams ?? {})
  } catch (error) {
    logError(error)
  }
}
