import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { useCanaryClient } from '@qogita/canary-client/provider'
import { cn } from '@qogita/ui/utils/cn'
import { useQuery } from '@tanstack/react-query'
import Link from 'next/link'
import { useAuthentication } from 'src/core/authentication/provider'
import { getCartQueries } from 'src/deprecated/api/cart-queries'
import { useLoginLink } from 'src/deprecated/components/LoginLink'
import { ActiveCartLink } from 'src/deprecated/components/pages/CartQidPage/CartLink'
import { formatCompactNumber } from 'src/lib/number'

export const MastheadCart = () => {
  const canaryClient = useCanaryClient()
  const { isAuthenticated } = useAuthentication()
  const { data: activeCart } = useQuery({
    ...getCartQueries(canaryClient).activeDetail(),
    enabled: isAuthenticated,
  })
  const loginLink = useLoginLink()

  if (!isAuthenticated) {
    // If the user is not authenticated, this is a link to login as the user can't view their cart
    // Rather than being a non-functional link
    return (
      <Link
        {...loginLink.getProps({
          className: 'rounded-full px-2 transition-all hover:bg-gray-100',
        })}
      >
        <CartIconWithCount />
      </Link>
    )
  }

  return (
    <ActiveCartLink
      className="rounded-full px-2 transition-all hover:bg-gray-100"
      aria-label="Your cart"
    >
      <CartIconWithCount cartLinesCount={activeCart?.cartlinesCount} />
    </ActiveCartLink>
  )
}

function CartIconWithCount({
  cartLinesCount = 0,
  className,
}: {
  cartLinesCount?: number
  className?: string
}) {
  return (
    <div className={cn('flex items-center', className)}>
      <ShoppingCartIcon className="size-6 fill-current sm:size-8" />
      <span
        className={cn(
          'bg-primary-700 rounded-full px-2 py-0.5 font-mono text-xs text-white transition-opacity',
          {
            'opacity-0': cartLinesCount === 0, // Hide badge when cart is empty but prevent layout shift
          },
        )}
      >
        {formatCompactNumber({ value: cartLinesCount, precision: 1 })}
      </span>
    </div>
  )
}
