import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { HeaderPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'

const HeaderPanelComponent = ({ blok }: { blok: HeaderPanelStoryblok }) => {
  const [headerBlok] = blok.header

  if (!headerBlok) return null

  return (
    <CenteredContent>
      <div
        {...storyblokEditable(blok)}
        className="py-18 flex max-w-5xl flex-col gap-6 text-balance text-center"
      >
        <StoryblokComponent
          key={headerBlok._uid}
          blok={headerBlok}
          className="gap-6"
        />
      </div>
    </CenteredContent>
  )
}

export const HeaderPanel = ({ blok }: { blok: HeaderPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <HeaderPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
