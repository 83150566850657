import { initDatadog } from '@qogita/logging'
import { useEffect } from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { useConsent } from 'src/core/consent'
import { environment } from 'src/deprecated/lib/environment.mjs'

const isDatadogEnabled = environment.NEXT_PUBLIC_DATADOG_RUM_ENABLED

export function DatadogInit() {
  const { consent } = useConsent()
  const isDatadogAccepted =
    consent.status !== 'loading' && consent.value.performance

  const { isAuthenticated } = useAuthentication()

  useEffect(() => {
    if (isDatadogAccepted && isDatadogEnabled) {
      initDatadog({
        trackResources: true,
        sessionReplaySampleRate: isAuthenticated ? 100 : 0,
        sessionSampleRate: isAuthenticated ? 100 : 5,
      })
    }
  }, [isAuthenticated, isDatadogAccepted])

  return null
}
