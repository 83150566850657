import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { TestimonialsRichMediaPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'

import { MiniTrustBox } from '../Trustpilot/MiniTrustBox'
import { Anchor } from './Anchor'
import { TestimonialsRichMediaCard } from './TestimonialsRichMediaCard'

const TestimonialsRichMediaPanelComponent = ({
  blok,
}: {
  blok: TestimonialsRichMediaPanelStoryblok
}) => {
  const cta = blok.cta?.[0]
  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-auto mb-8 flex w-full max-w-5xl flex-col gap-8 px-4 pt-8 md:pt-12">
        <h2 className="self-center text-balance text-4xl font-bold">
          {blok.heading}
        </h2>
        <MiniTrustBox />
        <div className="flex flex-col items-start gap-4 md:flex-row">
          {blok.testimonialCards.map((card) => (
            <TestimonialsRichMediaCard key={card._uid} blok={card} />
          ))}
        </div>
        {cta ? (
          <Anchor
            className="mt-6 border-none"
            blok={cta}
            color="primary"
            appearance="text"
            size="medium"
          >
            <Anchor.Icon>
              <ArrowRightIcon />
            </Anchor.Icon>
          </Anchor>
        ) : null}
      </div>
    </div>
  )
}
export const TestimonialsRichMediaPanel = ({
  blok,
}: {
  blok: TestimonialsRichMediaPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <TestimonialsRichMediaPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
