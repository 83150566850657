import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { isDeepEqual } from 'src/deprecated/lib/isDeepEqual'
import { z } from 'zod'

/**
 * Either a new query object or a function to derive a new query object from the previous one.
 */
type Updater<T> = T | ((previousValue: T) => T)

type NavigationOptions = {
  /**
   * The navigation type, either 'push' (default) or 'replace'.
   */
  navigationType?: 'push' | 'replace'
  /**
   * Flag to indicate whether the navigation should be shallow or not (defaults to false).
   */
  shallow?: boolean
  /**
   * Flag to enable scrolling to the updated URL (defaults to true).
   */
  scroll?: boolean
  /**
   * If provided the URL path will be updated too. Otherwise onlythe query params will be updated
   */
  pathname?: string
}

/**
 * A convenience hook for getting and setting the url params
 * Works very similarly to `useState` but for url params
 * The setter accepts a new query object or a function to derive a new query object from the previous one.
 * To bail out of an update, return the previous query object.
 *
 * Inspiration for this taken from React Router useSearchParams https://reactrouter.com/en/main/hooks/use-search-params
 *
 * We don't accept an initializer because our pages render on the server
 */
export function useQueryParams<
  SchemaType extends z.AnyZodObject | z.ZodEffects<z.AnyZodObject>,
>({ validationSchema }: { validationSchema: SchemaType }) {
  const { query: currentRawQuery, push, replace } = useRouter()

  type ParsedQuery = z.infer<SchemaType>

  const parsedQuery: ParsedQuery = validationSchema.parse(currentRawQuery)

  const setQueryParams = useCallback(
    /**
     * Set the URL query parameters based on the provided updater function or query object.
     * If the updater is a function, it receives the previous query object as an argument and should return a new query object.
     * If the updater is a query object, it replaces the current query object.
     *
     */
    function setQueryParams(
      updater: Updater<ParsedQuery>,
      {
        navigationType = 'push',
        shallow = false,
        scroll = true,
        pathname,
      }: NavigationOptions = {},
    ) {
      const updatedQuery: ParsedQuery =
        typeof updater === 'function' ? updater(parsedQuery) : updater

      // Bail early if the query hasn't changed
      if (isDeepEqual(updatedQuery, parsedQuery)) {
        return
      }

      // If any other parameter except page changes, we want to reset pagination
      const shouldResetPagination = Object.keys(updatedQuery).some(
        (key) => key !== 'page' && updatedQuery[key] !== parsedQuery[key],
      )
      if (shouldResetPagination && 'page' in updatedQuery) {
        delete updatedQuery.page
      }

      // If any parameters are null or undefined, remove them from the query so we don't get empty strings in the url
      for (const [key, value] of Object.entries(updatedQuery)) {
        if (value === null || value === undefined) {
          delete updatedQuery[key]
        }
      }

      // If any parameters match the default value from the zod schema, remove them from the query, so we don't get unnecessary params in the url
      for (const [key, value] of Object.entries(updatedQuery)) {
        const schemaShape =
          validationSchema instanceof z.ZodEffects
            ? validationSchema._def.schema.shape
            : validationSchema.shape
        const schemaField = schemaShape?.[key]
        const defaultValueFunction = schemaField?._def?.defaultValue
        const defaultValue =
          typeof defaultValueFunction === 'function'
            ? defaultValueFunction()
            : defaultValueFunction

        if (defaultValue === value) {
          delete updatedQuery[key]
        }
      }

      // If the consumer provided a pathname, we want to update that too
      const url: { pathname?: string; query: ParsedQuery } = {
        query: updatedQuery,
      }
      if (pathname) {
        url.pathname = pathname
      }

      const navigate = navigationType === 'push' ? push : replace

      navigate(url, undefined, { shallow, scroll })
    },
    [parsedQuery, push, replace, validationSchema],
  )

  return [parsedQuery, setQueryParams] as const
}
