import { useCanaryClient } from '@qogita/canary-client/provider'
import { cn } from '@qogita/ui/utils/cn'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Link, { LinkProps } from 'next/link'
import { ComponentPropsWithoutRef } from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { getCartQueries } from 'src/deprecated/api/cart-queries'
import { NextAnchor } from 'src/deprecated/components/NextAnchor'
import { UrlObject } from 'url'

/**
 * Custom hook that provides functionality for generating an active cart link.
 * The link is determined based on the allocation status of the active cart.
 * It also provides a function to prefetch cart data when the link is hovered or focused.
 *
 * @returns An object containing the active cart link, a function to prefetch cart data, and a function to get the active cart link props.
 */
export function useActiveCartLink() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const { isAuthenticated } = useAuthentication()
  const { data: activeCart } = useQuery({
    ...cartQueries.activeDetail(),
    enabled: isAuthenticated,
  })

  if (!activeCart) return null
  const { allocationStatus } = activeCart
  const unallocatedCartHref = { pathname: '/cart' }
  const allocatedCartHref = {
    pathname: '/cart/allocated',
  }

  const baseHref =
    allocationStatus === 'NOT_ALLOCATED'
      ? unallocatedCartHref
      : allocatedCartHref

  /**
   * Prefetches all relevant cart data to be used to speed up client-side navigation to cart
   */
  function prefetchCartData() {
    if (!activeCart) return
    queryClient.prefetchQuery(cartQueries.activeDetail())
    queryClient.prefetchQuery(cartQueries.list())
    if (
      allocationStatus === 'NOT_ALLOCATED' &&
      activeCart.pricingModel === 'Optimizer Based'
    ) {
      queryClient.prefetchQuery(cartQueries.linesList({ page: 1, size: 100 }))
    } else {
      queryClient.prefetchQuery(cartQueries.allocationsList())

      // Dropped lines is not necessary for Offer Based pricing model
      if (activeCart.pricingModel === 'Optimizer Based') {
        queryClient.prefetchQuery(cartQueries.droppedLinesList())
      }
    }
  }

  /**
   * Get all props for a link that will navigate to the correct active cart page
   * and prefetch the relevant cart data when hovered or focused
   *
   * @param props - Props to pass to the link component - event handlers will be merged
   * @returns Props to pass to the link component
   */
  function getActiveCartLinkProps({
    onMouseEnter,
    onFocus,
    href: customHref,
    ...props
  }: Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'href' | 'color' | 'size' | 'appearance'
  > & { href?: (baseHref: UrlObject) => LinkProps['href'] }): Omit<
    ComponentPropsWithoutRef<typeof NextAnchor>,
    'color' | 'size' | 'appearance'
  > {
    const href = customHref ? customHref(baseHref) : baseHref

    return {
      shallow: true,
      href,
      onMouseEnter: (event) => {
        onMouseEnter?.(event)
        prefetchCartData()
      },
      onFocus: (event) => {
        onFocus?.(event)
        prefetchCartData()
      },
      ...props,
    }
  }

  return {
    href: baseHref,
    prefetchCartData,
    getActiveCartLinkProps,
  }
}

/**
 * Links to the cart page. Bare, unstyled Link component.
 * If the cart has not been allocated takes you to the pre-allocation page
 * Otherwise takes you directly to the allocated cart page
 *
 * Be sure to use this component instead of a direct link to the cart page to avoid
 * breaking the cart allocation flow
 *
 * If the active cart isn't available yet, it'll return an invisible span to avoid layout shift
 *
 */
export function ActiveCartLink(
  props: Omit<ComponentPropsWithoutRef<typeof Link>, 'href'> & {
    href?: (baseHref: UrlObject) => LinkProps['href']
  },
) {
  const activeCartLink = useActiveCartLink()
  // If we don't have an active cart fetched yet, we can't render a meaningful link
  // instead render a span that's invisible, so we don't get layout shift from the link popping in
  if (!activeCartLink) {
    return (
      <span aria-hidden className={cn('invisible', props.className)}>
        {props.children}
      </span>
    )
  }
  const { getActiveCartLinkProps } = activeCartLink

  return <Link {...getActiveCartLinkProps(props)} />
}

/**
 * Links to the cart page. Styled design system NextAnchor component.
 * If the cart has not been allocated takes you to the pre-allocation page
 * Otherwise takes you directly to the allocated cart page
 *
 * Be sure to use this component instead of a direct link to the cart page to avoid
 * breaking the cart allocation flow
 *
 * If the active cart isn't available yet, it'll return an invisible span to avoid layout shift
 *
 */
export function ActiveCartAnchor({
  color,
  appearance,
  size,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof NextAnchor>, 'href'> & {
  href?: (baseHref: UrlObject) => LinkProps['href']
}) {
  const activeCartLink = useActiveCartLink()
  // If we don't have an active cart yet, we can't render a meaningful link
  // instead render a span that's invisible, so we don't get layout shift from the link popping in
  if (!activeCartLink) {
    return (
      <span aria-hidden className={cn('invisible', props.className)}>
        {props.children}
      </span>
    )
  }

  const { getActiveCartLinkProps } = activeCartLink

  return (
    <NextAnchor
      color={color}
      size={size}
      appearance={appearance}
      {...getActiveCartLinkProps(props)}
    />
  )
}
