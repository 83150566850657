/**
 * Formats a number into a compact representation with metric suffixes (K, M, B).
 *
 * @param {Object} params - The parameters for formatting
 * @param {number} params.value - The number to format
 * @param {number} [params.precision=1] - The number of decimal places to show
 * @returns {string} The formatted number as a string
 *
 * @example
 * formatCompactNumber({ value: 1234, precision: 1 })     // Returns "1.2K"
 * formatCompactNumber({ value: 1234567, precision: 1 })  // Returns "1.2M"
 * formatCompactNumber({ value: 1234, precision: 2 })     // Returns "1.23K"
 * formatCompactNumber({ value: 999, precision: 1 })      // Returns "999"
 * formatCompactNumber({ value: 1000000000, precision: 1 }) // Returns "1B"
 */
export function formatCompactNumber({
  value,
  precision = 1,
}: {
  value: number
  precision: number
}) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: precision,
    compactDisplay: 'short',
  }).format(value)
}
