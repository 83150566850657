import { CanaryClient } from '@qogita/canary-client'
import { useCanaryClient } from '@qogita/canary-client/provider'
import {
  AbaBankCreateRequest,
  EmailTokenVerifyRequest,
  IbanBankCreateRequest,
  PasswordChangeRequest,
  PatchedCurrentUserRequest,
  PhoneTokenVerifyRequest,
  PhoneVerifyRequest,
  SortCodeBankCreateRequest,
  User,
  UserCreateRequest,
} from '@qogita/canary-types'
import { isEUCountry } from '@qogita/countries/eu'
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAuthentication } from 'src/core/authentication/provider'
import { useEvaluateUserPricingModelFeatureFlag } from 'src/deprecated/hooks/featureFlags'
import { isDisallowedCurrency } from 'src/deprecated/lib/currency'
import { DisallowedUserCurrencyError } from 'src/deprecated/lib/custom-errors'

export function getUserQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['user'] as const,
    detail: () =>
      queryOptions({
        queryKey: queries.all(),
        queryFn: async () => {
          const user = await canaryClient.getUser()
          if (isDisallowedCurrency(user.currency)) {
            throw new DisallowedUserCurrencyError(
              'The user currency is now allowed.',
              { currency: user.currency },
            )
          }
          return user
        },
        select: (user) => enrichUser(user),
        retry: (failureCount, error) => {
          // If a DisallowedUserCurrencyError happens, retrying won't be helpful because the next two retries are expected to fail. So, we'll return false instead.
          if (error instanceof DisallowedUserCurrencyError) {
            return false
          }

          // The default retry count from React Query is 3. Since React Query does not export this default, we will leave it fixed.
          return failureCount < 3
        },
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    debtor: () =>
      queryOptions({
        queryKey: [...queries.all(), 'debtor'] as const,
        queryFn: () => canaryClient.getUserDebtor(),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    groups: () =>
      queryOptions({
        queryKey: [...queries.all(), 'groups'] as const,
        queryFn: () => canaryClient.getUserGroups(),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    bank: () => [...queries.all(), 'bank'] as const,
    bankInfo: () =>
      queryOptions({
        queryKey: [...queries.bank(), 'info'] as const,
        queryFn: () => canaryClient.getUserBankInfo(),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
  }
  return queries
}

/**
 * There are some extra convenience properties that we use in many places through the buyers app
 * So centralize them here to avoid duplication
 */
function enrichUser(user: User) {
  return {
    ...user,
    isEUOnlyBuyer:
      isEUCountry(user.country) &&
      user.buyingCountries.every((country) => isEUCountry(country)),
  }
}

export type BuyersUser = ReturnType<typeof enrichUser>

export function useUser() {
  const { isAuthenticated } = useAuthentication()
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)

  const userQuery = useQuery({
    ...userQueries.detail(),
    enabled: isAuthenticated,
    throwOnError: false,
  })

  useEvaluateUserPricingModelFeatureFlag(userQuery.data)

  return userQuery
}

export function useCreateUserMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UserCreateRequest) => canaryClient.createUser(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useUpdateUser() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const userQueries = getUserQueries(canaryClient)

  return useMutation({
    mutationFn: (data: PatchedCurrentUserRequest) =>
      canaryClient.updateUser(data),
    onSuccess: async (updatedUser) => {
      queryClient.setQueryData(userQueries.detail().queryKey, updatedUser)
      /*
       awaiting is important here as the user preferences affects the catalog and the PDP.
       this can mislead the buyer into thinking a product has less offers than it has or into
       opening the PDP of a product that is not available in the country they are browsing from.
      */
      await queryClient.invalidateQueries()
    },
  })
}

export function useUpdateUserPasswordMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: PasswordChangeRequest) =>
      canaryClient.updateUserPassword(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankAbaMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: AbaBankCreateRequest) =>
      canaryClient.createUserBankAba(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankIbanMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: IbanBankCreateRequest) =>
      canaryClient.createUserBankIban(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankSortCodeMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: SortCodeBankCreateRequest) =>
      canaryClient.createUserBankSortCode(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useRequestVerifyUserEmailMutation() {
  const canaryClient = useCanaryClient()
  return useMutation({
    mutationFn: () => canaryClient.requestVerifyUserEmail(),
    // We don't need to invalidate anything after this mutation - it doesn't change any data
  })
}

export function useConfirmVerifyUserEmailMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const userQueries = getUserQueries(canaryClient)

  return useMutation({
    mutationFn: (data: EmailTokenVerifyRequest) =>
      canaryClient.confirmVerifyUserEmail(data),
    onSuccess: async (verifiedEmailResponse) => {
      queryClient.setQueryData(
        userQueries.detail().queryKey,
        (previousUser) => {
          if (!previousUser) return

          return {
            ...previousUser,
            email: verifiedEmailResponse.email,
            isEmailVerified: verifiedEmailResponse.isEmailVerified,
          }
        },
      )
      await queryClient.invalidateQueries()
    },
  })
}

export function useRequestVerifyUserPhoneMutation() {
  const canaryClient = useCanaryClient()
  return useMutation({
    mutationFn: (data: PhoneVerifyRequest) =>
      canaryClient.requestVerifyUserPhone(data),
    // We don't need to invalidate anything after this mutation - it doesn't change any data
  })
}

export function useConfirmVerifyUserPhoneMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: PhoneTokenVerifyRequest) =>
      canaryClient.confirmVerifyUserPhone(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}
