import {
  ArchiveBoxIcon,
  ArrowDownCircleIcon,
  GiftIcon,
  MapPinIcon,
  ReceiptPercentIcon,
  StarIcon,
  UserCircleIcon,
  WalletIcon,
} from '@heroicons/react/20/solid'
import { Network } from '@qogita/canary-types'
import { Navigation } from '@qogita/ui/navigation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { TEMPORARY_APP_ROUTER_URL_PREFIX } from 'src/core/constants'
import { useUser } from 'src/deprecated/api/user-queries'
import { useIsAppRouterReferralsPageEnabled } from 'src/deprecated/hooks/featureFlags'
type NavigationLinkProps = {
  href: string // only allow string, for simpler url matching
  children: ReactNode
}

function getIsActive({ path, href }: { path: string; href: string }) {
  return path.includes(href)
}

function showReferralsPage(userNetwork: Network | null | undefined) {
  if (!userNetwork) return false
  const hasNetworkValue =
    Number(userNetwork?.value) > 0 ||
    Number(userNetwork?.kickback) > 0 ||
    Number(userNetwork?.commission) > 0
  return hasNetworkValue
}

function NavigationLink({ href, children }: NavigationLinkProps) {
  const { asPath } = useRouter()
  return (
    <Navigation.Item
      as={Link}
      href={href}
      isActive={getIsActive({ path: asPath, href })}
    >
      {children}
    </Navigation.Item>
  )
}

type AccountNavigationProps = {
  className?: string
  userNetwork?: Network
}
export function AccountNavigation({ className }: AccountNavigationProps) {
  const { asPath } = useRouter()
  const userQuery = useUser()
  const isAppRouterReferralsPageEnabled = useIsAppRouterReferralsPageEnabled()
  return (
    <nav className={className}>
      <Navigation>
        <NavigationLink href="/account/profile/">
          <Navigation.Icon>
            <UserCircleIcon />
          </Navigation.Icon>
          Profile
        </NavigationLink>
        <NavigationLink href="/account/addresses/">
          <Navigation.Icon>
            <MapPinIcon />
          </Navigation.Icon>
          Addresses
        </NavigationLink>
        <NavigationLink href="/account/orders/">
          <Navigation.Icon>
            <ArchiveBoxIcon />
          </Navigation.Icon>
          Orders
        </NavigationLink>
        <NavigationLink href="/account/watchlist/">
          <Navigation.Icon>
            <StarIcon />
          </Navigation.Icon>
          Watch list
        </NavigationLink>
        <Navigation.Section
          isActive={getIsActive({ path: asPath, href: '/account/wallet/' })}
        >
          <Navigation.SectionHeading>
            <Navigation.Icon>
              <WalletIcon />
            </Navigation.Icon>
            Wallet
          </Navigation.SectionHeading>
          <Navigation.SectionItems>
            <NavigationLink href="/account/wallet/cash/">Cash</NavigationLink>
            <NavigationLink href="/account/wallet/promotions/">
              Promotions
            </NavigationLink>
          </Navigation.SectionItems>
        </Navigation.Section>
        <NavigationLink href="/account/buy-now-pay-later/">
          <Navigation.Icon>
            <ReceiptPercentIcon />
          </Navigation.Icon>
          Buy Now, Pay Later
        </NavigationLink>
        <Navigation.Section
          isActive={getIsActive({
            path: asPath,
            href: '/account/promotions/',
          })}
        >
          <Navigation.SectionHeading>
            <Navigation.Icon>
              <GiftIcon />
            </Navigation.Icon>
            Promotions
          </Navigation.SectionHeading>
          <Navigation.SectionItems>
            <NavigationLink href="/account/promotions/promocode/">
              Promo code
            </NavigationLink>
            {showReferralsPage(userQuery.data?.network) ? (
              <NavigationLink
                href={
                  isAppRouterReferralsPageEnabled
                    ? `${TEMPORARY_APP_ROUTER_URL_PREFIX}/account/promotions/referrals/`
                    : '/account/promotions/referrals/'
                }
              >
                Referrals
              </NavigationLink>
            ) : null}
          </Navigation.SectionItems>
        </Navigation.Section>
        <NavigationLink href="/account/catalog/">
          <Navigation.Icon>
            <ArrowDownCircleIcon />
          </Navigation.Icon>
          Catalog
        </NavigationLink>
      </Navigation>
    </nav>
  )
}
