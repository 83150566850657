'use client'

import { Button } from '@qogita/ui/button'
import { ModalTrigger } from '@qogita/ui/modal'
import { useConsent } from 'src/core/consent'

import { ConsentSettings } from './consent-settings'

export function ConsentBanner() {
  const { consent, acceptAll } = useConsent()

  if (consent.status !== 'default') {
    // We don't show the banner if the user has already consented or rejected
    return null
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 flex justify-center md:px-4 md:pb-12">
      <div className="max-w-site-content flex flex-col gap-6 border bg-white px-6 py-4 shadow lg:flex-row lg:items-center">
        <p className="text-xs text-gray-700">
          By clicking “Accept All” you agree to the storing of cookies on your
          device to enhance site navigation, analyze site usage and assist in
          our marketing efforts. You can choose “Cookie Settings” for more
          information and to customize your settings and disable all or some
          non-essential cookies.
        </p>
        <div className="flex shrink-0 flex-col-reverse justify-end gap-3 md:flex-row">
          <ConsentSettings
            trigger={
              <ModalTrigger asChild>
                <Button color="primary" appearance="outlined" size="medium">
                  Cookie settings
                </Button>
              </ModalTrigger>
            }
          />

          <Button
            color="primary"
            appearance="contained"
            size="medium"
            onClick={acceptAll}
          >
            Accept all
          </Button>
        </div>
      </div>
    </div>
  )
}
