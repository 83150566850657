import { load } from '@fingerprintjs/botd'
import { isUserAgentBot } from 'src/lib/bot-detection'

export const getIsBotUser = async ({
  isBotDetectionEnabled,
}: {
  isBotDetectionEnabled: boolean
}) => {
  if (!isBotDetectionEnabled) {
    return false
  }
  const isCrawler = isUserAgentBot(navigator.userAgent)

  if (isCrawler) {
    return true
  }

  return load()
    .then((botd) => botd.detect())
    .then(({ bot }) => {
      return bot
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}
