import { storyblokEditable } from '@storyblok/react'
import React, { Children, ReactNode } from 'react'
import slugify from 'slugify'
import {
  LegalPanelStoryblok,
  RichtextStoryblok,
} from 'src/core/cms/storyblok-component-types'
import { NextAnchor } from 'src/deprecated/components/NextAnchor'
import { render } from 'storyblok-rich-text-react-renderer'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'

const getNodeText = (node: ReactNode): string => {
  const firstChild = Children.toArray(node)[0]

  if (!firstChild) {
    return ''
  }

  if (typeof firstChild === 'string') {
    return firstChild
  }

  if (
    typeof firstChild === 'object' &&
    firstChild &&
    'props' in firstChild &&
    firstChild.props &&
    typeof firstChild.props === 'object' &&
    'children' in firstChild.props &&
    typeof firstChild.props.children === 'string'
  ) {
    return firstChild.props.children
  }
  return ''
}

const getIdFromReactNode = (node: ReactNode) => {
  return slugify(getNodeText(node))
}

export const getTOCHeadings = ({
  docs,
  levels = [2, 3],
}: {
  docs: LegalPanelStoryblok['content']
  /**
   * Levels that should be included in the TOC, defaults to [2, 3] to match previous sanity implementation
   */
  levels?: number[]
}) => {
  return docs.flatMap((item) =>
    item.content.content
      ? item.content.content.filter(
          (item) =>
            item.type === 'heading' && levels.includes(item.attrs.level),
        )
      : [],
  )
}

/**
 * Quick and easy function to render the TOC, but only works by coincidence
 * because requires you to pass the same headings to both renderTOC and renderRichText.
 *
 * It's fine right now because it's only used in the legal pages where the TOC is
 * extracted from the content, and the content has only one rich text block.
 *
 * However, if that changes in the future, this function will need to be updated,
 * or create a new one for the a new case
 */
export const renderTOC = (headings: ReturnType<typeof getTOCHeadings>) => {
  return render(
    {
      type: 'doc',
      content: headings,
    },
    {
      nodeResolvers: {
        heading: (children, { level }) => {
          if (level === 2) {
            return (
              <a
                className="text-primary-700 text-base"
                href={`#${getIdFromReactNode(children)}`}
              >
                {children}
              </a>
            )
          }
          if (level === 3) {
            return (
              <a
                className="text-primary-700 ml-4 text-base"
                href={`#${getIdFromReactNode(children)}`}
              >
                {children}
              </a>
            )
          }
          return null
        },
      },
    },
  )
}

export const renderRichText = (
  richTextDocument: RichtextStoryblok | RichtextStoryblok[] | undefined,
) => {
  return render(richTextDocument, {
    markResolvers: {
      link: (children, props) =>
        props.href ? (
          <NextAnchor
            size="inline"
            color="custom"
            target={props.target}
            href={props.href}
          >
            {children}
          </NextAnchor>
        ) : null,
    },
    nodeResolvers: {
      ordered_list: (children) => {
        return <ul className="ml-5 list-decimal">{children}</ul>
      },
      paragraph: (children) => {
        return <p className="text-pretty text-gray-700">{children}</p>
      },
      list_item: (children) => {
        return (
          // The list renders with a p after the li,
          // the [&>p]:mb-2 will add the margin to the p element.
          // We don't add the margin to the li directly because it
          // would break nested lists.
          <li className="text-pretty text-gray-700 [&>p]:mb-2">{children}</li>
        )
      },
      bullet_list: (children) => {
        return <ul className="ml-5 flex list-disc flex-col">{children}</ul>
      },
      heading: (children, { level }) => {
        if (level === 2) {
          return (
            <h2
              id={getIdFromReactNode(children)}
              className="text-2xl font-bold md:text-3xl"
            >
              {children}
            </h2>
          )
        }
        if (level === 3) {
          return (
            <h3
              className="text-lg font-bold md:text-2xl"
              id={getIdFromReactNode(children)}
            >
              {children}
            </h3>
          )
        }
        return <p>{children}</p>
      },
    },
  })
}

export const RichText = ({ blok }: { blok: RichtextStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <div className="flex flex-col gap-4" {...storyblokEditable(blok)}>
        {renderRichText(blok.content)}
      </div>
    </CmsPanelErrorBoundary>
  )
}
