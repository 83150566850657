import { logError } from '@qogita/logging/browser-logger'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { LayoutHead } from 'src/deprecated/components/Layout'

type Props = { children: ReactNode }
export function AppErrorBoundary({ children }: Props) {
  const { pathname } = useRouter()
  return (
    <ErrorBoundary
      onError={(error) => {
        logError(
          new Error(`AppErrorBoundary: ${error.message}`, { cause: error }),
        )
      }}
      FallbackComponent={FallbackComponent}
      key={pathname}
    >
      {children}
    </ErrorBoundary>
  )
}

function FallbackComponent() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 p-2">
      <LayoutHead title="Something went wrong" description="" />
      <h1 className="text-2xl sm:text-3xl">Something went wrong</h1>
    </div>
  )
}
