import { useQuery } from '@tanstack/react-query'
import { BingScript } from 'src/core/tracking/providers/bing/script'
import { GTagScript } from 'src/core/tracking/providers/gtag/script'
import { MetaScript } from 'src/core/tracking/providers/meta/script'
import { TikTokScript } from 'src/core/tracking/providers/tiktok/script'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { getIsBotUser } from 'src/deprecated/lib/report/bots'

export function TrackingScripts() {
  const isBotQuery = useQuery({
    queryKey: ['isBot'],
    queryFn: () =>
      getIsBotUser({
        isBotDetectionEnabled: environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION,
      }),
  })

  const isBot = isBotQuery.isSuccess && isBotQuery.data
  if (isBot) {
    // We don't load tracking scripts for bots
    return null
  }

  const isAnalyticsEnabled = environment.NEXT_PUBLIC_ANALYTICS_ENABLED

  if (!isAnalyticsEnabled) {
    return null
  }

  return (
    <>
      <GTagScript />
      <MetaScript />
      <BingScript />
      <TikTokScript />
    </>
  )
}
