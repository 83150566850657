import { createIcon } from 'src/deprecated/components/Icon'

export const ApplePayIcon = createIcon(
  <>
    <g clipPath="url(#apple-pay-clip-path)">
      <path
        d="M8.233 1.9C9.416.34 11.06.332 11.06.332s.245 1.466-.93 2.877C8.875 4.718 7.45 4.471 7.45 4.471s-.268-1.186.783-2.572ZM7.6 5.497c.608 0 1.737-.837 3.207-.837 2.53 0 3.525 1.8 3.525 1.8s-1.946.996-1.946 3.411c0 2.724 2.425 3.663 2.425 3.663s-1.695 4.772-3.985 4.772c-1.052 0-1.87-.71-2.977-.71-1.108 0-2.25.736-2.98.736-2.09 0-4.732-4.526-4.732-8.164S2.373 4.71 4.47 4.71c1.363 0 2.421.787 3.13.787Z"
        fill="#999"
      />
    </g>
    <defs>
      <clipPath id="apple-pay-clip-path">
        <path
          fill="#fff"
          transform="translate(.137 .333)"
          d="M0 0h14.674v18H0z"
        />
      </clipPath>
    </defs>
  </>,
  'ApplePayIcon',
  '0 0 15 19',
)
