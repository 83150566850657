'use client'

import BotD from '@fingerprintjs/botd'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

/**
 * Detects if a user agent is a bot based on a common list of known crawlers
 *
 * This is likely not very reliable, as malicious bots can alter these values really easily. But it can be used as part of a fail-fast strategy
 *
 * @param userAgent - The user agent string to detect - get this from `headers().get('user-agent')` on the server or `navigator.userAgent` on the client
 * @returns true if the user agent is a bot, false otherwise
 */
export function isUserAgentBot(userAgent: string) {
  return CRAWLER_USERAGENT_STRINGS.some((uaString) =>
    userAgent.toLowerCase().includes(uaString),
  )
}

const BotDetectionContext = createContext<'loading' | 'bot' | 'human'>(
  'loading',
)
export function useBotDetection() {
  return useContext(BotDetectionContext)
}

export function BotDetectionProvider({ children }: { children: ReactNode }) {
  const [botDetection, setBotDetection] = useState<'loading' | 'bot' | 'human'>(
    'loading',
  )

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (isUserAgentBot(userAgent)) {
      // If the user agent is a bot, we can set the state immediately
      setBotDetection('bot')
      return
    }

    // If the user agent doesn't detect as a bot, we'll call botD to get a more reliable result
    BotD.load()
      .then((botD) => botD.detect())
      .then(({ bot }) => {
        setBotDetection(bot ? 'bot' : 'human')
      })
      .catch((error) => {
        console.error('Error detecting bot', error)
        // Fall back to human if there's an error so we don't under-track
        setBotDetection('human')
      })
  }, [])

  return (
    <BotDetectionContext.Provider value={botDetection}>
      {children}
    </BotDetectionContext.Provider>
  )
}

const CRAWLER_USERAGENT_STRINGS = [
  'ahrefsbot',
  'ahrefssiteaudit',
  'amazonbot',
  'baiduspider',
  'bingbot',
  'bingpreview',
  'claudebot',
  'dataforseo-bot',
  'facebookexternal',
  'mj12bot',
  'petalbot',
  'pinterest',
  'screaming frog',
  'seekportbot',
  'semrushbot',
  'uptimerobot',
  'yahoo! slurp',
  'yandexbot',

  // a whole bunch of goog-specific crawlers
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
  'adsbot-google',
  'apis-google',
  'duplexweb-google',
  'feedfetcher-google',
  'google favicon',
  'google web preview',
  'google-read-aloud',
  'googlebot',
  'googleweblight',
  'mediapartners-google',
  'storebot-google',
]
