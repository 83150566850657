import { User } from '@qogita/canary-types'
import { logError } from '@qogita/logging/browser-logger'
import ky from 'ky'
import { getCookie } from 'src/deprecated/lib/cookie'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { isUserAgentBot } from 'src/lib/bot-detection'
import { MetaConversionEvent } from 'src/pages/api/meta/conversions'

export const metaIdentifyUser = (
  user: Pick<User, 'qid' | 'email' | 'account' | 'phone'>,
) => {
  if (!window.fbq) return

  try {
    // Params here are meta advanced matching API values
    // https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
    window.fbq('init', environment.NEXT_PUBLIC_META_PIXEL_ID, {
      em: user.email,
      fn: user.account,
      ph: user.phone,
      external_id: user.qid,
    })
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

export const metaEvent: MetaEvent = (eventName, parameters, option) => {
  if (!window.fbq) return

  try {
    window.fbq('track', eventName, parameters, option)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

export const metaCustomEvent = (
  eventName: string,
  parameters: facebook.Pixel.CustomParameters,
  option?: facebook.Pixel.EventIDOptions,
) => {
  if (!window.fbq) return

  try {
    window.fbq('trackCustom', eventName, parameters, option)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logError(error)
  }
}

// This allows us to get proper type checking on the Meta Pixel Standard Events
// https://developers.facebook.com/docs/meta-pixel/reference
// This is the subset of the standard events we currently use
// but can be expanded as needed
interface MetaEvent {
  (
    eventName: 'AddToCart',
    parameters: facebook.Pixel.AddToCartParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
  (
    eventName: 'ViewContent',
    parameters: facebook.Pixel.ViewContentParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
  (
    eventName: 'Search',
    parameters: facebook.Pixel.SearchParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
  (
    eventName: 'InitiateCheckout',
    parameters: facebook.Pixel.InitiateCheckoutParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
  (
    eventName: 'Purchase',
    parameters: facebook.Pixel.PurchaseParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
  (
    eventName: 'CompleteRegistration',
    parameters: facebook.Pixel.CompleteRegistrationParameters,
    option?: facebook.Pixel.EventIDOptions,
  ): void
}

export async function hashString(input: string | undefined) {
  if (!window.crypto || !input) return

  const inputUint8 = new TextEncoder().encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', inputUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHexString = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')

  return hashHexString
}

type MetaEventUserData =
  | {
      client_ip_address?: string
      email?: string
      phone?: string | null
    }
  | undefined

type GetMetaConversionUserData = (userData: MetaEventUserData) => Promise<{
  user_data: {
    client_user_agent: string
    fbc?: string
    em?: string
    ph: string | null
    client_ip_address?: string
  }
}>

const getMetaConversionUserData: GetMetaConversionUserData = async (
  userData,
) => {
  // Meta Pixel automatically stores ClickID value in the _fbc browser cookie once available
  const cookie = getCookie(document.cookie, '_fbc')

  // Email addresses sent to the Meta Conversions API must be SHA256 hashed
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#email
  const userEmail = userData?.email
  const normalisedUserEmail = userEmail?.trim().toLowerCase()
  const hashedUserEmail = await hashString(normalisedUserEmail)
  const userPhone = userData?.phone
  const normalisedUserPhone = userPhone?.trim().toLowerCase()
  const hashedUserPhone = await hashString(normalisedUserPhone)

  const ipAddress = userData?.client_ip_address

  return {
    user_data: {
      client_user_agent: window.navigator.userAgent,
      ...(cookie && { fbc: cookie }), // Only send fbc if it exists
      ...(hashedUserEmail && { em: hashedUserEmail }),
      ...((hashedUserPhone && { ph: hashedUserPhone }) || { ph: null }),
      ...(ipAddress && { client_ip_address: ipAddress }),
    },
  }
}

export async function metaConversionEvent(
  data: Omit<MetaConversionEvent, 'event_source_url' | 'user_data'> & {
    user_data?: Pick<
      MetaConversionEvent['user_data'],
      'client_ip_address' | 'email' | 'phone'
    >
  },
) {
  const client_user_agent = window.navigator.userAgent
  const isCrawler = isUserAgentBot(client_user_agent)
  if (isCrawler) return
  const metaConversionUserData = await getMetaConversionUserData(data.user_data)

  const defaultMetaConversionData = {
    event_source_url: window.location.href,
    ...metaConversionUserData,
  }
  return ky
    .post('/api/meta/conversions/', {
      json: { ...data, ...defaultMetaConversionData },
    })
    .catch((error) => {
      logError(error)
    })
}
