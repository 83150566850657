import { createIcon } from 'src/deprecated/components/Icon'

export const IdealIcon = createIcon(
  <>
    <g clipPath="url(#ideal-clip-path)">
      <path
        d="M6.198 10.465H2.89v5.493h3.308v-5.493ZM6.449 7.718a1.873 1.873 0 1 1-3.746 0 1.873 1.873 0 0 1 3.746 0Z"
        fill="#0D0508"
      />
      <path
        d="M12.464.333H.596v18h11.868v-.003c2.593-.036 4.647-.68 6.104-1.92 1.773-1.508 2.672-3.889 2.672-7.077 0-1.523-.237-2.89-.703-4.062-.446-1.121-1.105-2.075-1.959-2.833-1.504-1.337-3.614-2.06-6.114-2.1V.332Zm-.224 1.196c2.3 0 4.217.623 5.544 1.802 1.5 1.333 2.26 3.352 2.26 6.002 0 5.252-2.553 7.804-7.805 7.804H1.791V1.528h10.45-.001Z"
        fill="#0D0508"
      />
      <path
        d="M11.914 15.964H7.718V2.984h4.026c3.5 0 7.224 1.38 7.224 6.507 0 5.419-3.724 6.473-7.224 6.473h.17Z"
        fill="#CD0067"
      />
      <path
        d="M6.994 6.334h1.152c.26 0 .459.02.595.06.183.054.34.15.47.288.131.137.23.306.299.506.068.2.102.445.102.738 0 .257-.032.478-.096.665-.078.227-.19.41-.335.551-.109.107-.256.19-.443.25a1.905 1.905 0 0 1-.557.065H6.994V6.334Zm.63.53v2.064h.472c.177 0 .304-.01.382-.03a.584.584 0 0 0 .255-.13.658.658 0 0 0 .166-.3c.042-.14.064-.33.064-.57 0-.242-.022-.427-.064-.556a.713.713 0 0 0-.18-.302.602.602 0 0 0-.292-.147 2.882 2.882 0 0 0-.519-.03h-.284Z"
        fill="#fff"
      />
      <path
        d="M6.909 6.249v3.294H8.18c.24 0 .436-.023.583-.07.198-.063.359-.154.477-.27.154-.149.273-.346.356-.585.066-.194.1-.427.1-.692 0-.3-.036-.558-.107-.766a1.457 1.457 0 0 0-.317-.537 1.132 1.132 0 0 0-.508-.31 2.309 2.309 0 0 0-.619-.064H6.91Zm1.237.17c.25 0 .443.02.57.057.168.05.314.138.433.265.122.128.216.287.28.474.065.19.097.429.097.71 0 .247-.03.462-.09.638a1.298 1.298 0 0 1-.314.518c-.1.097-.237.174-.41.23-.13.04-.308.061-.531.061H7.08V6.419h1.066Z"
        fill="#fff"
      />
      <path
        d="M7.538 6.778v2.236h.558c.185 0 .317-.01.403-.033a.672.672 0 0 0 .29-.149.734.734 0 0 0 .19-.339 2.08 2.08 0 0 0 .068-.596c0-.251-.022-.442-.068-.581a.8.8 0 0 0-.202-.337.688.688 0 0 0-.333-.168 2.915 2.915 0 0 0-.537-.032h-.369Zm.37.171c.311 0 .443.015.5.028a.524.524 0 0 1 .252.126.633.633 0 0 1 .157.266c.04.12.06.298.06.528 0 .23-.02.415-.06.546a.583.583 0 0 1-.141.262.503.503 0 0 1-.22.11 1.664 1.664 0 0 1-.36.028h-.387V6.949h.199ZM10.324 9.458V6.334h2.312v.53h-1.682v.692h1.565v.529h-1.565v.843h1.742v.53h-2.372Z"
        fill="#fff"
      />
      <path
        d="M10.239 6.249v3.294h2.542v-.7H11.04V8.17h1.565v-.7h-1.565v-.52h1.682v-.701H10.24Zm2.312.17v.36h-1.683v.862h1.565V8h-1.565v1.014h1.742v.358h-2.2V6.42h2.14ZM16.222 9.458h-.686l-.272-.709h-1.248l-.259.709h-.669l1.214-3.124h.67l1.25 3.124Zm-1.16-1.238-.431-1.157-.422 1.157h.853Z"
        fill="#fff"
      />
      <path
        d="m14.244 6.249-1.28 3.294h.853l.259-.709h1.13l.272.71h.87l-1.317-3.295h-.787Zm.672.17 1.18 2.953h-.501l-.273-.708h-1.366l-.258.708h-.485L14.36 6.42h.555Z"
        fill="#fff"
      />
      <path
        d="M14.087 8.305h1.097l-.554-1.49-.543 1.49Zm.545-.995.306.824h-.607l.3-.824ZM16.75 9.458V6.36h.63v2.568h1.569v.53h-2.2Z"
        fill="#fff"
      />
      <path
        d="M16.664 6.275v3.268h2.37v-.7h-1.57V6.275h-.8Zm.63.17v2.569h1.57v.358h-2.029V6.445h.46-.001Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="ideal-clip-path">
        <path
          fill="#fff"
          transform="translate(.596 .333)"
          d="M0 0h20.644v18H0z"
        />
      </clipPath>
    </defs>
  </>,
  'IdealIcon',
  '0 0 22 19',
)
