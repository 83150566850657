'use client'

import { trimmedString } from '@qogita/validation/string'
import Script from 'next/script'
import { useEffect } from 'react'
import { useConsent } from 'src/core/consent'

const NEXT_PUBLIC_GA_MEASUREMENT_ID = trimmedString().parse(
  process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
)

const NEXT_PUBLIC_GOOGLE_ADS_TAG_ID = trimmedString().parse(
  process.env.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID,
)

export function GTagScript() {
  const { consent } = useConsent()

  useEffect(() => {
    if (!window.gtag) return
    if (consent.status === 'loading') return

    window.gtag('consent', 'update', {
      ad_storage: consent.value.marketing ? 'granted' : 'denied',
      analytics_storage: consent.value.performance ? 'granted' : 'denied',
      ad_user_data: consent.value.marketing ? 'granted' : 'denied',
      ad_personalization: consent.value.marketing ? 'granted' : 'denied',
    })
  }, [consent.status, consent])

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            wait_for_update: 500,
          });
          gtag('js', new Date());

          gtag('config', '${NEXT_PUBLIC_GA_MEASUREMENT_ID}', {'allow_enhanced_conversions':true});
          gtag('config', '${NEXT_PUBLIC_GOOGLE_ADS_TAG_ID}', {'allow_enhanced_conversions':true});
        `}
      </Script>
    </>
  )
}
