import { User } from '@qogita/canary-types'
import { environment } from 'src/deprecated/lib/environment.mjs'

export function gtagEvent(
  // We need this to be a string literal type, but also allow for custom events
  eventName: Gtag.EventNames | (string & {}),
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) {
  if (!window.gtag) return

  window.gtag('event', eventName, eventParams)
}

export function gtagConversion(
  conversionId: string,
  params: Pick<Gtag.EventParams, 'value' | 'currency' | 'transaction_id'> = {},
) {
  if (!window.gtag) return

  window.gtag('event', 'conversion', {
    send_to: `${environment.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID}/${conversionId}`,
    ...params,
  })
}

type UserData = Pick<User, 'email' | 'phone'>
export function gtagSetUserData({ email, phone }: UserData) {
  if (!window.gtag) return

  window.gtag('set', 'user_data', {
    email,
    // gtag recommends that if our site doesn't collect one of the fields, we should remove the field entirely rather than leaving it blank: https://support.google.com/google-ads/answer/13258081?sjid=16017382173959970421-EU
    ...(phone ? { phone_number: phone } : {}),
  })
}
