import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import {
  IconListItemStoryblok,
  IconListStoryblok,
} from 'src/core/cms/storyblok-component-types'

export function IconList({ blok }: { blok: IconListStoryblok }) {
  return (
    <ul {...storyblokEditable(blok)} className="flex flex-col gap-2">
      {blok.items.map((item) => (
        <StoryblokComponent key={item._uid} blok={item} />
      ))}
    </ul>
  )
}

export function IconListItem({ blok }: { blok: IconListItemStoryblok }) {
  return (
    <li {...storyblokEditable(blok)} className="flex gap-2">
      <div className="shrink-0 self-start">
        {blok.icon.map((iconBlok) => (
          <StoryblokComponent key={iconBlok._uid} blok={iconBlok} />
        ))}
      </div>
      <div className="text-gray-700">{blok.description}</div>
    </li>
  )
}
