import { Anchor } from '@qogita/ui/anchor'
import Link, { LinkProps } from 'next/link'
import { ComponentProps } from 'react'
import { ProgressBarLink } from 'src/components/progress-bar'
export function NextAnchor({
  color,
  size,
  appearance,
  className,
  children,
  hideProgressBar,
  ...props
}: Omit<ComponentProps<typeof Anchor>, 'asChild' | 'href'> &
  LinkProps & {
    /**
     * By default this component will show a progress bar (usually at the top of the page) while navigating.
     * If you pass `hideProgressBar` then the progress bar will not be shown.
     */
    hideProgressBar?: boolean
  }) {
  return (
    <Anchor
      asChild
      color={color}
      appearance={appearance}
      size={size}
      className={className}
    >
      {hideProgressBar ? (
        <Link {...props}>{children}</Link>
      ) : (
        <ProgressBarLink {...props}>{children}</ProgressBarLink>
      )}
    </Anchor>
  )
}

NextAnchor.Icon = Anchor.Icon
