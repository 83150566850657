'use client'
// Consent is done on the client side so we don't have to opt the whole app into dynamic rendering
// in order to read cookies

import Cookies from 'js-cookie'
import { useRouter } from 'next/navigation'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { z } from 'zod'

export const COOKIE_CONSENT_KEY = 'q_consent'

export const consentValueSchema = z.object({
  functional: z.boolean(),
  performance: z.boolean(),
  marketing: z.boolean(),
})

export type ConsentValue = z.infer<typeof consentValueSchema>
export type ConsentCategory = keyof ConsentValue

type Consent =
  | {
      status: 'loading'
    }
  | {
      status: 'default'
      value: ConsentValue
    }
  | {
      status: 'ready'
      value: ConsentValue
    }

type ConsentContext = {
  consent: Consent
  acceptAll: () => void
  updateConsent: (value: ConsentValue) => void
  hasConsent: (consentType: keyof ConsentValue) => boolean
}

const ConsentContext = createContext<ConsentContext | undefined>(undefined)

export function useConsent() {
  const context = useContext(ConsentContext)
  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentProvider')
  }
  return context
}

// Default consent to be used prior to the user interacting with our cookie banner.
const defaultConsentValue: ConsentValue = {
  functional: true,
  performance: true,
  marketing: true,
}

function ensureClientSide(
  message: string = 'This function must only run on the client',
) {
  if (typeof window === 'undefined') {
    throw new TypeError(message)
  }
}

function getConsentFromCookie(): ConsentValue | null {
  ensureClientSide('Consent cookie should only be read on the client')
  const currentConsent = Cookies.get(COOKIE_CONSENT_KEY)
  if (!currentConsent) return null

  try {
    return consentValueSchema.parse(JSON.parse(currentConsent))
  } catch {
    return null
  }
}

function setConsentCookie(value: ConsentValue) {
  ensureClientSide('Consent cookie should only be set on the client')
  Cookies.set(COOKIE_CONSENT_KEY, JSON.stringify(value), {
    expires: 365,
    path: '/',
  })
}

export function ConsentProvider({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean
  children: ReactNode
}) {
  // For logged out users we show a cookie banner and we store their consent preferences in a cookie
  // But for logged in users we override this setting and always allow all cookies
  // So if status is logged in, we return a hardcoded `fullyAcceptedConsent` value instead of whatever's in the cookie
  const [rawConsent, setConsent] = useState<Consent>({ status: 'loading' })
  const consent: Consent = isAuthenticated
    ? { status: 'ready', value: defaultConsentValue }
    : rawConsent

  useEffect(() => {
    const cookieConsent = getConsentFromCookie()
    setConsent(
      cookieConsent
        ? { status: 'ready', value: cookieConsent }
        : { status: 'default', value: defaultConsentValue },
    )
  }, [])

  const router = useRouter()

  const updateConsent = (value: ConsentValue) => {
    setConsent({ status: 'ready', value })
    setConsentCookie(value)
    router.refresh()
  }

  function hasConsent(consentType: keyof ConsentValue) {
    if (consent.status === 'loading') {
      return false
    }

    return consent.value[consentType]
  }

  const acceptAll = () => {
    updateConsent(defaultConsentValue)
  }

  return (
    <ConsentContext.Provider
      value={{ consent, acceptAll, updateConsent, hasConsent }}
    >
      {children}
    </ConsentContext.Provider>
  )
}
