import { Anchor } from '@qogita/ui/anchor'
import Script from 'next/script'
import { useEffect, useRef } from 'react'
import { useConsent } from 'src/core/consent'

const TrustpilotScript = () => {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  const shouldLoadScript = !isConsentAccepted

  return shouldLoadScript ? (
    <Script
      id="trustpilot-script-loader"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  ) : null
}

export const Grid = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <>
      <TrustpilotScript />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="539adbd6dec7e10e686debee"
        data-businessunit-id="61e6a507aa6867337dd09e64"
        data-style-height="800px"
        data-style-width="100%"
        data-stars="5"
        data-review-languages="en"
      >
        <Anchor
          href="https://uk.trustpilot.com/review/www.qogita.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </Anchor>
      </div>
    </>
  )
}
