import '../global.css'

import { CanaryClient, HTTPError } from '@qogita/canary-client'
import { CanaryClientProvider } from '@qogita/canary-client/provider'
import { logError } from '@qogita/logging/browser-logger'
import {
  apiPlugin,
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
  storyblokInit,
} from '@storyblok/react'
import {
  HydrationBoundary,
  keepPreviousData,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ReactNode, startTransition, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  AuthenticationProvider,
  useAuthentication,
} from 'src/core/authentication/provider'
import { PageStoryblok } from 'src/core/cms/storyblok-component-types'
import { ConsentProvider, useConsent } from 'src/core/consent'
import { useUser } from 'src/deprecated/api/user-queries'
import { AppErrorBoundary } from 'src/deprecated/components/AppErrorBoundary'
import { BuyerCannotCheckoutAlert } from 'src/deprecated/components/BuyerCannotCheckoutAlert'
import { BannerPanel } from 'src/deprecated/components/cms/BannerPanel'
import {
  BenefitsCard,
  BenefitsPanel,
} from 'src/deprecated/components/cms/BenefitsPanel'
import {
  BrandCard,
  BrandsPanel,
} from 'src/deprecated/components/cms/BrandsPanel'
import {
  CategoriesPanel,
  CategoryCard,
} from 'src/deprecated/components/cms/CategoriesPanel'
import { Column } from 'src/deprecated/components/cms/Column'
import { Cta } from 'src/deprecated/components/cms/Cta'
import { FaqPanel } from 'src/deprecated/components/cms/FaqPanel'
import { Header } from 'src/deprecated/components/cms/Header'
import { HeaderPanel } from 'src/deprecated/components/cms/HeaderPanel'
import { Hero } from 'src/deprecated/components/cms/Hero'
import { HeroPanel } from 'src/deprecated/components/cms/HeroPanel'
import { Icon } from 'src/deprecated/components/cms/Icon'
import { IconList, IconListItem } from 'src/deprecated/components/cms/IconList'
import { ImageBlok } from 'src/deprecated/components/cms/Image'
import { LegalPanel } from 'src/deprecated/components/cms/LegalPanel'
import { PeoplePanel } from 'src/deprecated/components/cms/PeoplePanel'
import { RichText } from 'src/deprecated/components/cms/RichText'
import { SellViaQogitaPanel } from 'src/deprecated/components/cms/SellViaQogitaPanel'
import { StoryPanel } from 'src/deprecated/components/cms/StoryPanel'
import { TestimonialCard } from 'src/deprecated/components/cms/TestimonialCard'
import { TestimonialSimplePanel } from 'src/deprecated/components/cms/TestimonialSimplePanel'
import { TestimonialsPanel } from 'src/deprecated/components/cms/TestimonialsPanel'
import { TestimonialsRichMediaCard } from 'src/deprecated/components/cms/TestimonialsRichMediaCard'
import { TestimonialsRichMediaPanel } from 'src/deprecated/components/cms/TestimonialsRichMediaPanel'
import { ThreeColumnPanel } from 'src/deprecated/components/cms/ThreeColumnPanel'
import { TrustpilotGridPanel } from 'src/deprecated/components/cms/TrustpilotGridPanel'
import { TwoColumnPanel } from 'src/deprecated/components/cms/TwoColumnPanel'
import { TwoColumnPanelNew } from 'src/deprecated/components/cms/TwoColumnPanelNew'
import { UspsPanel } from 'src/deprecated/components/cms/UspsPanel'
import { FeatureFlagProvider } from 'src/deprecated/components/FeatureFlagProvider'
import { RouteErrorBoundary } from 'src/deprecated/components/RouteErrorBoundary'
import { Toaster } from 'src/deprecated/components/Toast/Toaster'
import { useProductFruitsEnabled } from 'src/deprecated/hooks/featureFlags'
import { DatadogInit } from 'src/deprecated/lib/datadog/datadog.client'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { LoqateClientProvider } from 'src/deprecated/lib/loqate'
import { AnalyticsProviderPagesRouter } from 'src/deprecated/lib/report/AnalyticsProvider'
import { Tapfiliate } from 'src/deprecated/lib/report/Tapfiliate'
import { ConsentBanner } from 'src/features/consent/consent-banner'

import { VerifiedRedirect } from '../deprecated/components/pages/VerificationPage/VerifiedRedirect'
import { TrackingScripts } from '../deprecated/components/TrackingScripts'

/**
 * We only want to load the support widget if we're in production
 */
function SupportWidget() {
  const isVisible = environment.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET

  if (!isVisible) return null

  const HUBSPOT_SNIPPET_SRC = '//js.hs-scripts.com/139645065.js'
  return (
    <Script
      id="hs-script-loader"
      src={HUBSPOT_SNIPPET_SRC}
      strategy="lazyOnload"
    />
  )
}

function TrustpilotScript() {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  const shouldLoadScript = isConsentAccepted

  return shouldLoadScript ? (
    <Script
      id="trustpilot-script-loader"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  ) : null
}

const StoryblokPage = ({ blok }: { blok: PageStoryblok }) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.body?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </main>
  )
}

const StoryblokFallbackComponent = ({ blok }: { blok: SbBlokData }) => {
  useEffect(() => {
    logError('Storyblok component not found', { component: blok.component })
  }, [blok.component])

  return null
}

storyblokInit({
  accessToken: environment.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    page: StoryblokPage,
    hero: Hero,
    heroPanel: HeroPanel,
    bannerPanel: BannerPanel,
    categoriesPanel: CategoriesPanel,
    categoryCard: CategoryCard,
    uspsPanel: UspsPanel,
    benefitsPanel: BenefitsPanel,
    benefitsCard: BenefitsCard,
    faqPanel: FaqPanel,
    brandsPanel: BrandsPanel,
    brand: BrandCard,
    sellViaQogitaPanel: SellViaQogitaPanel,
    testimonialsPanel: TestimonialsPanel,
    peoplePanel: PeoplePanel,
    twoColumnPanel: TwoColumnPanel,
    twoColumnPanelNew: TwoColumnPanelNew,
    threeColumnPanel: ThreeColumnPanel,
    richText: RichText,
    testimonialCard: TestimonialCard,
    testimonialSimplePanel: TestimonialSimplePanel,
    image: ImageBlok,
    cta: Cta,
    icon: Icon,
    header: Header,
    column: Column,
    iconList: IconList,
    iconListItem: IconListItem,
    headerPanel: HeaderPanel,
    storyPanel: StoryPanel,
    legalPanel: LegalPanel,
    trustpilotGridPanel: TrustpilotGridPanel,
    testimonialsRichMediaPanel: TestimonialsRichMediaPanel,
    testimonialsRichMediaCard: TestimonialsRichMediaCard,
  },
  enableFallbackComponent: true,
  customFallbackComponent: StoryblokFallbackComponent,
})

function RouteChangeProgressBar() {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleStart = () => {
      startTransition(() => setIsLoading(true))
    }
    const handleComplete = () => {
      startTransition(() => setIsLoading(false))
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  return (
    <div
      role="progressbar"
      aria-label="Page loading"
      aria-live="polite"
      aria-busy={isLoading}
      data-busy={isLoading}
      className="group fixed left-0 right-0 top-0 z-50 h-[2px] data-[busy='false']:opacity-0 motion-safe:bg-gray-50"
    >
      <div className="motion-safe:bg-primary-700 h-full group-data-[busy='true']:motion-safe:animate-[progress_2s_linear_infinite]" />
    </div>
  )
}

const ProductFruits = dynamic(
  () => import('react-product-fruits').then((module_) => module_.ProductFruits),
  { ssr: false },
)

function ProductFruitsWrapper() {
  const { data: user } = useUser()
  const isProductFruitsEnabled = useProductFruitsEnabled()

  const workspaceCode = environment.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_CODE

  if (!isProductFruitsEnabled || !user || !global.window || !workspaceCode) {
    return null
  }

  return (
    <ErrorBoundary fallback={null} onError={(error) => logError(error)}>
      <ProductFruits
        workspaceCode={workspaceCode}
        language="en"
        user={{
          username: user.email,
          email: user.email,
          signUpAt: new Date(user.createdAt).toISOString(),
          props: {
            qid: user.qid,
            pricingModel: user.pricingModel,
            env: environment.NEXT_PUBLIC_VERCEL_ENV,
          },
        }}
        // The following config is only needed if we experience issues with routing
        // https://help.productfruits.com/en/article/developers-integration-react-nextjs#routing-in-nextjs
        // config={{ disableLocationChangeDetection: true }}
      />
    </ErrorBoundary>
  )
}

function PagesRouterConsentProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuthentication()
  return (
    <ConsentProvider isAuthenticated={isAuthenticated}>
      {children}
    </ConsentProvider>
  )
}

const QogitaApp = ({ Component, pageProps }: AppProps) => {
  const serverAuthProps = pageProps.auth ?? { isAuthenticated: false }

  const [queryClient] = useState(() => {
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          throwOnError: true,

          placeholderData: keepPreviousData,
          retry: (failureCount, error) => {
            // There's no point retrying if the error is a 404 or 401
            if (
              error instanceof HTTPError &&
              [404, 401].includes(error.response.status)
            ) {
              return false
            }

            // Otherwise retry up to 3 times
            return failureCount < 3
          },
          staleTime: 1000 * 60 * 5, // 5 minutes
        },
        mutations: {
          onSuccess: () => {
            queryClient.invalidateQueries()
          },
          onError: () => {
            queryClient.invalidateQueries()
          },
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          if (error instanceof HTTPError && error.response.status === 401) {
            // If we get a 401, we should log the user out
            window.location.reload()
          }
        },
      }),
    })
    return client
  })

  const [canaryClient] = useState(
    () =>
      new CanaryClient({
        prefixUrl: environment.NEXT_PUBLIC_API_BASE_URL,
        xQogitaApplication: 'www.qogita.com',
      }),
  )

  return (
    <AppErrorBoundary>
      <CanaryClientProvider client={canaryClient}>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <AuthenticationProvider {...serverAuthProps}>
              <PagesRouterConsentProvider>
                <AnalyticsProviderPagesRouter
                  writeKey={environment.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
                >
                  <FeatureFlagProvider
                    initialState={pageProps.featureFlagState}
                  >
                    <ProductFruitsWrapper />
                    <VerifiedRedirect />
                    <RouteErrorBoundary>
                      <LoqateClientProvider
                        apiKey={environment.NEXT_PUBLIC_LOQATE_API_KEY}
                      >
                        <RouteChangeProgressBar />
                        <Component {...pageProps} />
                        <Toaster />
                        <BuyerCannotCheckoutAlert />
                      </LoqateClientProvider>
                    </RouteErrorBoundary>
                    <TrackingScripts />
                    <Tapfiliate />
                    <SupportWidget />
                    <TrustpilotScript />
                    <ConsentBanner />
                    <DatadogInit />
                  </FeatureFlagProvider>
                </AnalyticsProviderPagesRouter>
              </PagesRouterConsentProvider>
            </AuthenticationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </HydrationBoundary>
        </QueryClientProvider>
      </CanaryClientProvider>
    </AppErrorBoundary>
  )
}

export default QogitaApp
