import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import {
  BenefitsCardStoryblok,
  BenefitsPanelStoryblok,
} from 'src/core/cms/storyblok-component-types'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

import { Anchor } from './Anchor'
import { renderRichText } from './RichText'

export function BenefitsCard({ blok }: { blok: BenefitsCardStoryblok }) {
  const ctaList = blok.cta
  const cta = ctaList?.[0]
  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-2 md:flex-row md:gap-6"
    >
      <div className="shrink-0 self-start md:p-1">
        {blok.icon?.map((icon) => (
          <StoryblokComponent blok={icon} key={icon._uid} />
        ))}
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-balance text-2xl font-bold">{blok.title}</h3>
        {blok.body ? (
          <div className="space-y-2 text-pretty text-gray-700">
            {renderRichText(blok.body)}
          </div>
        ) : null}
        {cta ? (
          <Anchor
            blok={cta}
            color="primary"
            appearance="text"
            size="small"
            className="self-start"
          >
            <Anchor.Icon>
              <ArrowRightIcon />
            </Anchor.Icon>
          </Anchor>
        ) : null}
      </div>
    </div>
  )
}

export function BenefitsPanel({ blok }: { blok: BenefitsPanelStoryblok }) {
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div className="flex flex-col items-center gap-6 py-8 md:gap-8 md:py-12">
          <h2 className="max-w-2xl text-balance text-4xl font-bold md:text-center">
            {blok.heading}
          </h2>
          {blok.description ? (
            <p className="max-w-2xl text-pretty text-lg font-light md:text-center">
              {blok.description}
            </p>
          ) : null}
          <div className="grid grid-cols-1 gap-6 self-stretch md:grid-cols-2 md:gap-8">
            {blok.benefits?.map((block) => (
              <BenefitsCard key={block._uid} blok={block} />
            ))}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}
