import {
  ArchiveBoxIcon,
  ArrowDownCircleIcon,
  MapPinIcon,
  ReceiptPercentIcon,
  StarIcon,
  UserCircleIcon,
  WalletIcon,
} from '@heroicons/react/20/solid'
import {
  GiftIcon,
  UserCircleIcon as UserCircleIcon24,
} from '@heroicons/react/24/solid'
import { Anchor } from '@qogita/ui/anchor'
import { Button } from '@qogita/ui/button'
import { DeprecatedDropdownMenu } from '@qogita/ui/deprecated-dropdown-menu'
import { Drawer } from '@qogita/ui/drawer'
import { cn } from '@qogita/ui/utils/cn'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { ComponentPropsWithoutRef, forwardRef, useState } from 'react'
import { WhatsAppIcon } from 'src/components/icons/whatsapp'
import { useAuthentication } from 'src/core/authentication/provider'
import { useUser } from 'src/deprecated/api/user-queries'
import { useLoginLink } from 'src/deprecated/components/LoginLink'
import { AccountNavigation } from 'src/deprecated/components/pages/AccountPage/AccountNavigation'
import { useRegisterLink } from 'src/deprecated/components/RegisterLink'
import { useLogoutUser } from 'src/deprecated/hooks/api/useLogoutUser'
import { useTrackEvent } from 'src/deprecated/lib/report/tracking'
import { vercelImageLoader } from 'src/lib/image'

const MastheadText = () => {
  const { isAuthenticated } = useAuthentication()
  const { data: user, isPending } = useUser()

  const isUserNameLoading = isAuthenticated && isPending

  return (
    <span className="flex flex-row items-center">
      <span
        className={cn('mr-1 flex flex-col text-right transition-opacity', {
          'opacity-0': isUserNameLoading,
        })}
      >
        {user ? (
          <>
            <span className="max-w-[8rem] overflow-hidden overflow-ellipsis whitespace-nowrap text-xs leading-none">
              Hello, {user.account.split(' ')[0]}
            </span>
            <span className="text-xs font-bold leading-none">Account</span>
          </>
        ) : (
          <>
            <span className="whitespace-nowrap text-xs leading-none">
              Hello
            </span>
            <span className="text-xs font-bold leading-none">Login</span>
          </>
        )}
      </span>
      <UserCircleIcon24 className="h-8 w-8 fill-current" />
    </span>
  )
}

const MenuLink = forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithoutRef<typeof Link>
>(({ children, className, ...props }, ref) => {
  return (
    <Link
      className={clsx(
        'flex flex-row items-center px-3 py-2 text-sm',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </Link>
  )
})

MenuLink.displayName = 'MenuLink'

const AccountManagerSection = () => {
  const { data: user } = useUser()
  const accountManager = user?.accountManager
  const { trackAccountManagerContactClicked } = useTrackEvent()

  if (!accountManager) return null

  return (
    <DeprecatedDropdownMenu.Section>
      <div className="px-6 py-4 sm:px-3 sm:py-3">
        <div className="flex items-center gap-2">
          {accountManager.profilePicture ? (
            <Image
              src={accountManager.profilePicture}
              alt={`${accountManager.account} picture`}
              width={32}
              height={32}
              className="rounded-full"
              loader={vercelImageLoader}
            />
          ) : (
            <UserCircleIcon24 className="h-4 w-4 fill-gray-300" />
          )}
          <div className="ml-1 flex flex-col items-start">
            <p className="whitespace-nowrap text-base font-bold sm:text-xs sm:font-normal sm:text-gray-500">
              Your account manager
            </p>
            <p className="max-w-[11rem] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {accountManager.account}
            </p>
          </div>
        </div>
        <div className="mt-2 flex flex-col items-start gap-1 sm:gap-0">
          {accountManager.whatsappNumber ? (
            <Anchor
              href={`https://wa.me/${accountManager.whatsappNumber.replaceAll(
                /\D/g,
                '',
              )}`}
              className="text-sm"
              target="_blank"
              onClick={() =>
                trackAccountManagerContactClicked({
                  medium: 'whatsApp',
                })
              }
            >
              <span className="flex items-center">
                <WhatsAppIcon className="h-6.5 w-6.5" />
                {accountManager.whatsappNumber}
              </span>
            </Anchor>
          ) : null}
          <Anchor
            href={`mailto:${accountManager.accountManagerEmail}`}
            className="text-sm"
            onClick={() =>
              trackAccountManagerContactClicked({
                medium: 'email',
              })
            }
          >
            {accountManager.accountManagerEmail}
          </Anchor>
        </div>
        <Anchor
          href={accountManager.meetingLink}
          target="_blank"
          className="mt-3 text-sm sm:mt-4"
          onClick={() =>
            trackAccountManagerContactClicked({
              medium: 'calendly',
            })
          }
        >
          <span className="font-medium tracking-wider">Book a Call</span>
        </Anchor>
      </div>
    </DeprecatedDropdownMenu.Section>
  )
}

type MastheadAccountProps = {
  className?: string
}
export const MastheadAccount = ({ className }: MastheadAccountProps) => {
  const { data: user } = useUser()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { trackAccountMenuOpened } = useTrackEvent()

  const logout = useLogoutUser()
  const loginLink = useLoginLink()
  const registerLink = useRegisterLink()

  return (
    <>
      {/* MOBILE we show a side drawer for navigation */}
      <div className={clsx('sm:hidden', className)}>
        <button
          onClick={() => {
            setIsDrawerOpen(true)
            trackAccountMenuOpened()
          }}
        >
          <UserCircleIcon24 className="mt-[0.313rem] h-6 w-6 fill-current sm:h-8 sm:w-8" />
        </button>
        <Drawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          align="right"
          className="z-[9999999]" //avoids support widget overlapping the Drawer's register button
        >
          <Drawer.Backdrop />
          <Drawer.Content className="flex !h-dvh flex-col divide-y-[0.5px] divide-gray-300">
            <div className="px-7 pb-4 pt-6">
              <p>Hello{user ? `, ${user.account.split(' ')[0]}` : null}</p>
              <h2 className="pt-2 text-2xl">Account</h2>
            </div>
            <AccountNavigation className="w-full grow overflow-auto bg-gray-50 py-6" />
            <AccountManagerSection />
            {user ? (
              <div className="bg-gray-50 p-7">
                <Button
                  color="primary"
                  appearance="text"
                  onClick={() => {
                    logout.mutate()
                    setIsDrawerOpen(false)
                  }}
                >
                  <span className="font-medium text-gray-900">Sign out</span>
                </Button>
              </div>
            ) : (
              <div className="grid grid-cols-2 divide-x bg-gray-50 py-7">
                <Link
                  {...loginLink.getProps({
                    className: 'py-0.5 text-center font-medium',
                  })}
                >
                  Login
                </Link>
                <Link
                  {...registerLink.getProps({
                    className: 'py-0.5 text-center font-medium',
                  })}
                >
                  Register
                </Link>
              </div>
            )}
          </Drawer.Content>
        </Drawer>
      </div>

      {/* Desktop we show the full dropdown menu */}
      <DeprecatedDropdownMenu
        as="div"
        className={clsx('relative hidden sm:block', className)}
        alignment="right"
      >
        {({ open }) => (
          <>
            <DeprecatedDropdownMenu.Button
              className="flex items-center rounded-full pl-3 hover:bg-gray-100 active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)]"
              onClick={() => !open && trackAccountMenuOpened()}
            >
              <MastheadText />
            </DeprecatedDropdownMenu.Button>
            <DeprecatedDropdownMenu.Items className="z-40">
              <>
                {user ? (
                  <DeprecatedDropdownMenu.Section>
                    <div className="px-3 py-2">
                      <div className="text-xs text-gray-500">Signed in as</div>{' '}
                      <div className="truncate text-sm">{user?.email}</div>
                    </div>
                  </DeprecatedDropdownMenu.Section>
                ) : (
                  <DeprecatedDropdownMenu.Section>
                    <div className="flex flex-row">
                      <DeprecatedDropdownMenu.Item className="mr-1 flex w-full justify-center text-center text-base font-medium">
                        <MenuLink {...loginLink.getProps()}>Login</MenuLink>
                      </DeprecatedDropdownMenu.Item>
                      <div className="my-2 w-[1px] bg-gray-400" />
                      <DeprecatedDropdownMenu.Item className="ml-1 flex w-full justify-center text-center text-base text-gray-500">
                        <MenuLink {...registerLink.getProps()}>
                          Register
                        </MenuLink>
                      </DeprecatedDropdownMenu.Item>
                    </div>
                  </DeprecatedDropdownMenu.Section>
                )}
              </>

              <DeprecatedDropdownMenu.Section>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/profile/">
                    <UserCircleIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Profile
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/addresses/">
                    <MapPinIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Addresses
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/orders/">
                    <ArchiveBoxIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Orders
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/watchlist/">
                    <StarIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Watch list
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href={'/account/wallet/cash/'}>
                    <WalletIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Wallet
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/buy-now-pay-later/">
                    <ReceiptPercentIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Buy Now, Pay Later
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/promotions/promocode/">
                    <GiftIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Promotions
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
                <DeprecatedDropdownMenu.Item>
                  <MenuLink href="/account/catalog/">
                    <ArrowDownCircleIcon className="h-4.5 w-4.5 mr-3 text-gray-400" />{' '}
                    Catalog
                  </MenuLink>
                </DeprecatedDropdownMenu.Item>
              </DeprecatedDropdownMenu.Section>
              <AccountManagerSection />
              {user ? (
                <DeprecatedDropdownMenu.Section>
                  <DeprecatedDropdownMenu.Item
                    className="w-full px-2 py-1 text-left text-base font-medium"
                    onClick={() => logout.mutate()}
                  >
                    <button>Sign Out</button>
                  </DeprecatedDropdownMenu.Item>
                </DeprecatedDropdownMenu.Section>
              ) : null}
            </DeprecatedDropdownMenu.Items>
          </>
        )}
      </DeprecatedDropdownMenu>
    </>
  )
}
