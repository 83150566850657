import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { UspsPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'

import { Anchor } from './Anchor'
import { renderRichText } from './RichText'

function UspsPanelComponent({ blok }: { blok: UspsPanelStoryblok }) {
  return (
    <div
      {...storyblokEditable(blok)}
      className="mx-auto grid max-w-5xl grid-cols-1 gap-6 px-4 py-8 md:grid-cols-3 md:gap-8 md:py-12"
    >
      {blok.usps.map((usp) => {
        const ctaList = usp.cta
        const cta = ctaList?.[0]
        return (
          <div
            key={usp._uid}
            {...storyblokEditable(usp)}
            className="flex flex-col gap-4"
          >
            <h2 className="text-balance text-2xl font-bold">{usp.title}</h2>
            <div className="space-y-2 text-pretty text-gray-700">
              {renderRichText(usp.body)}
            </div>
            {cta ? (
              <Anchor
                blok={cta}
                color="primary"
                appearance="text"
                size="small"
                className="self-start"
              >
                <Anchor.Icon>
                  <ArrowRightIcon />
                </Anchor.Icon>
              </Anchor>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}
export const UspsPanel = ({ blok }: { blok: UspsPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <UspsPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
