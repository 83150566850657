/**
 * Type safe alternative to Array.includes when working with arrays
 * of literal values
 * https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
 * @param collection array of literals
 * @param element element to check if in array
 * @returns boolean
 */
export function includes<T extends U, U>(
  collection: ReadonlyArray<T>,
  element: U,
): element is T {
  return collection.includes(element as T)
}

/**
 * Takes any number of arrays and returns a new array with only unique values
 * @param arrays Any number of arrays to combine and deduplicate
 * @returns Array with unique values from all input arrays
 */
export function uniqueValues<T>(...arrays: T[][]): T[] {
  return [...new Set(arrays.flat())]
}
