import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { CtaStoryblok } from 'src/core/cms/storyblok-component-types'

import { Anchor } from './Anchor'

export const Cta = ({ blok }: { blok: CtaStoryblok }) => {
  return (
    <Anchor blok={blok} color="custom" appearance="text" size="small">
      <Anchor.Icon>
        <ArrowRightIcon />
      </Anchor.Icon>
    </Anchor>
  )
}
