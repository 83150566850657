'use client'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@qogita/ui/button'
import { Modal, useModalContext } from '@qogita/ui/modal'
import { Switch } from '@qogita/ui/switch'
import { cn } from '@qogita/ui/utils/cn'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ComponentProps } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NextAnchor } from 'src/components/next-anchor'
import { ConsentValue, consentValueSchema, useConsent } from 'src/core/consent'

export function ConsentSettingsModalContent() {
  const { close } = useModalContext()
  const { consent, updateConsent, acceptAll } = useConsent()

  const defaultValues =
    consent.status === 'ready'
      ? consent.value
      : ({
          functional: false,
          performance: false,
          marketing: false,
        } satisfies ConsentValue)

  const { handleSubmit, control } = useForm<ConsentValue>({
    defaultValues,
    resolver: zodResolver(consentValueSchema),
  })

  return (
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Cookie settings</Modal.Title>
      </Modal.Header>
      {consent.status === 'loading' ? null : (
        <form
          onSubmit={handleSubmit(
            (updatedConsent) => {
              updateConsent(updatedConsent)
              close()
            },
            (error) => {
              console.warn('Consent cookie settings validation failed', error)
            },
          )}
        >
          <Modal.Body>
            <p className="border-b pb-6 text-sm text-gray-700">
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience. Because we respect your right to
              privacy, you can choose not to allow some types of cookies. Click
              on the different category headings to find out more and change our
              default settings. However, blocking some types of cookies may
              impact your experience of the site and the services we are able to
              offer.{' '}
              <NextAnchor
                color="primary"
                appearance="text"
                href="/legal/cookies/"
              >
                Learn more
              </NextAnchor>
            </p>
            <h3 className="my-6 text-base font-bold">
              Manage consent preferences
            </h3>
            <Accordion type="single" collapsible>
              <AccordionItem value="essential">
                <div className="flex items-center justify-between">
                  <AccordionTrigger>
                    <AccordionIndicator />
                    Essential cookies
                  </AccordionTrigger>
                  <p className="text-gray-500">Always active</p>
                </div>
                <AccordionContent className="px-6 text-sm text-gray-700">
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms. You can set your
                  browser to block or alert you about these cookies, but some
                  parts of the site will not then work. These cookies do not
                  store any personally identifiable information.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="performance">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="performance">
                    <AccordionIndicator />
                    Performance cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="performance"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="performance"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="px-6 text-sm text-gray-700">
                  These cookies allow us to count visits and traffic sources so
                  we can measure and improve the performance of our site. They
                  help us to know which pages are the most and least popular and
                  see how visitors move around the site. All information these
                  cookies collect is aggregated and therefore anonymous. If you
                  do not allow these cookies we will not know when you have
                  visited our site, and will not be able to monitor its
                  performance.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="functional">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="functional">
                    <AccordionIndicator />
                    Functional cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="functional"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="functional"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="px-6 text-sm text-gray-700">
                  These cookies enable the website to provide enhanced
                  functionality and personalization. They may be set by us or by
                  third party providers whose services we have added to our
                  pages. If you do not allow these cookies then some or all of
                  these services may not function properly.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="marketing">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="marketing">
                    <AccordionIndicator />
                    Marketing cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="marketing"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="marketing"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="px-6 text-sm text-gray-700">
                  These cookies may be set through our site by our advertising
                  partners. They may be used by those companies to build a
                  profile of your interests and show you relevant adverts on
                  other sites. They do not store directly personal information,
                  but are based on uniquely identifying your browser and
                  internet device. If you do not allow these cookies, you will
                  experience less targeted advertising.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Actions className="flex-col-reverse">
              <Button
                color="primary"
                appearance="outlined"
                type="submit"
                size="medium"
              >
                Confirm choices
              </Button>
              <Button
                color="primary"
                appearance="contained"
                size="medium"
                onClick={() => {
                  acceptAll()
                  close()
                }}
              >
                Accept all
              </Button>
            </Modal.Actions>
          </Modal.Footer>
        </form>
      )}
    </Modal.Content>
  )
}

const Accordion = AccordionPrimitive.Root

const AccordionItem = AccordionPrimitive.Item

function AccordionTrigger({
  className,
  children,
  ...props
}: ComponentProps<typeof AccordionPrimitive.Trigger>) {
  return (
    <AccordionPrimitive.Header className={cn('flex', className)}>
      <AccordionPrimitive.Trigger
        className="flex flex-1 items-center justify-between gap-3 py-2 transition-all [&[data-state=open]>svg]:rotate-180"
        {...props}
      >
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
}

function AccordionIndicator({ className, ...props }: ComponentProps<'svg'>) {
  return (
    <ChevronDownIcon
      className={cn(
        'h-5 w-5 shrink-0 text-gray-400 transition-transform duration-200',
        className,
      )}
      {...props}
    />
  )
}

function AccordionContent({
  className,
  children,
  ...props
}: ComponentProps<typeof AccordionPrimitive.Content>) {
  return (
    <AccordionPrimitive.Content
      className={cn(
        'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden',
        className,
      )}
      {...props}
    >
      <div className="pb-4 pt-0">{children}</div>
    </AccordionPrimitive.Content>
  )
}
