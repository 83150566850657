import { Modal } from '@qogita/ui/modal'
import { ReactNode } from 'react'

import { ConsentSettingsModalContent } from './_components/consent-modal-content'

export function ConsentSettings({ trigger }: { trigger: ReactNode }) {
  return (
    <Modal>
      {trigger}
      <ConsentSettingsModalContent />
    </Modal>
  )
}
