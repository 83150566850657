import { XMarkIcon } from '@heroicons/react/16/solid'
import { cn } from '@qogita/ui/utils/cn'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import { ComponentProps } from 'react'

export const Drawer = DialogPrimitive.Root

export const DrawerTrigger = DialogPrimitive.Trigger

const DrawerClose = DialogPrimitive.Close

const DrawerPortal = DialogPrimitive.Portal

function DrawerOverlay({
  className,
  ...props
}: ComponentProps<typeof DialogPrimitive.Overlay>) {
  return (
    <DialogPrimitive.Overlay
      className={cn(
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-gray-900/40',
        className,
      )}
      {...props}
    />
  )
}

const drawerVariants = cva(
  'data-[state=open]:animate-in data-[state=closed]:animate-out fixed z-50 flex flex-col bg-white shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        left: 'data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left inset-y-0 left-0 h-full w-full sm:max-w-sm sm:border-r',
        right:
          'data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right inset-y-0 right-0 h-full w-full sm:max-w-sm sm:border-l',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
)

export function DrawerContent({
  side,
  className,
  children,
  // Defaults to undefined to avoid RadixUI warnings about not having provided a description for the dialog
  'aria-describedby': ariaDescribedby = undefined,
  ...props
}: ComponentProps<typeof DialogPrimitive.Content> &
  VariantProps<typeof drawerVariants>) {
  return (
    <DrawerPortal>
      <DrawerOverlay />
      <DialogPrimitive.Content
        className={cn(drawerVariants({ side }), className)}
        aria-describedby={ariaDescribedby}
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DrawerPortal>
  )
}

export function DrawerHeader({
  className,
  children,
  ...props
}: ComponentProps<'div'>) {
  return (
    <div
      className={cn(
        'flex items-center gap-4 border-b border-gray-200 p-6',
        className,
      )}
      {...props}
    >
      {children}
      <DrawerClose className="outline-primary-700 ml-auto rounded-sm">
        <XMarkIcon className="h-6 w-6 text-gray-400" />
        <span className="sr-only">Close</span>
      </DrawerClose>
    </div>
  )
}

export function DrawerBody({
  className,
  children,
  ...props
}: ComponentProps<'div'>) {
  return (
    <div className={cn('flex-1 overflow-y-auto p-6', className)} {...props}>
      {children}
    </div>
  )
}

export function DrawerFooter({ className, ...props }: ComponentProps<'div'>) {
  return (
    <div className={cn('border-t border-gray-200 p-6', className)} {...props} />
  )
}

export function DrawerTitle({
  className,
  ...props
}: ComponentProps<typeof DialogPrimitive.Title>) {
  return (
    <DialogPrimitive.Title
      className={cn('text-lg font-bold text-gray-900', className)}
      {...props}
    />
  )
}
