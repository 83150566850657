'use client'

import { useCanaryClient } from '@qogita/canary-client/provider'
import { User } from '@qogita/canary-types'
import { clearUser as clearLoggerUser, identifyUser } from '@qogita/logging'
import { logError } from '@qogita/logging/browser-logger'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useQuery } from '@tanstack/react-query'
import { LDFlagSet } from 'launchdarkly-react-client-sdk'
import { usePathname, useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  Suspense,
  use,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { ConsentCategory, useConsent } from 'src/core/consent'
import { getUserQueries } from 'src/deprecated/api/user-queries'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { useBotDetection } from 'src/lib/bot-detection'
import { Promisable } from 'type-fest'
import { z } from 'zod'

import { getIsBotUser } from './bots'
import { gtagSetUserData } from './Gtag'
import { metaIdentifyUser } from './Meta'
import { tikTokIdentifyUser } from './TikTok'

const analytics = new AnalyticsBrowser()

async function page(properties?: Record<string, unknown>) {
  try {
    analytics.page(undefined, undefined, properties)
  } catch (error) {
    logError(error)
  }
}

export async function track(
  event: string,
  properties?: Record<string, unknown>,
) {
  try {
    analytics.track(event, { ...properties })
  } catch (error) {
    logError(error)
  }
}

type AnalyticsUser = Pick<
  User,
  | 'qid'
  | 'email'
  | 'account'
  | 'company'
  | 'accountManager'
  | 'phone'
  | 'pricingModel'
  | 'createdAt'
>

async function identify(
  user: AnalyticsUser,
  traits?: {
    flags?: LDFlagSet
  },
) {
  try {
    identifyUser(user)

    analytics.identify(user.qid, {
      email: user.email,
      name: user.account,
      //@ts-expect-error - TODO: we should probably change this to an ISO string
      createdAt: user.createdAt,
      //@ts-expect-error - TODO: this doesn't match what segment expects a company to be
      company: user.company,
      pricingModel: user.pricingModel,
      isKeyAccount: Boolean(user.accountManager),
      flags: {
        // Currently we have tonnes of flags defined in LD and we don't want to send
        // all of these into Segment (and eventually all our destinations) to limit
        // the amount of data we're sending. We're only sending flags that are relevant
        // to experiments we're running.
        // Once we have cleaned up our flags in LD, we can remove this filtering.
        newTaxonomyEnabled: traits?.flags?.['new_taxonomy_enabled'],
      },
    })

    gtagSetUserData({
      email: user.email,
      phone: user.phone,
    })

    metaIdentifyUser(user)
    tikTokIdentifyUser({
      email: user.email,
      phoneNumber: user.phone,
    })
  } catch (error) {
    logError(error)
  }
}

export function reset() {
  try {
    analytics.reset()
    clearLoggerUser()
  } catch (error) {
    logError(error)
  }
}

/**
 * This calls identify whenever any of the relevant user data changes
 * We don't want to call identify on every render, so we only call it when the user data changes
 *
 * We mostly need this so that when a user opens the site, and they were already logged in, they get identified
 * Rather than it just being triggered by the login form submission
 */
function useReidentifyUser({ user }: { user: AnalyticsUser | null }) {
  useEffect(() => {
    if (user?.qid) {
      identify({
        qid: user.qid,
        email: user.email,
        account: user.account,
        company: user.company,
        accountManager: user.accountManager,
        phone: user.phone,
        pricingModel: user.pricingModel,
        createdAt: user.createdAt,
      })
    }
  }, [
    user?.qid,
    user?.account,
    user?.accountManager,
    user?.company,
    user?.email,
    user?.phone,
    user?.pricingModel,
    user?.createdAt,
  ])
}

/**
 * Selected functions from segment analytics, augmented with things like bot detection
 * and GA session data
 */
const customAnalytics = {
  page,
  identify,
  reset,
  track,
}

export const AnalyticsContext = createContext<
  typeof customAnalytics | undefined
>(undefined)

export function useAnalytics() {
  const context = useContext(AnalyticsContext)

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}

function usePageTrackingPagesRouter() {
  const { events, asPath, pathname } = useRouter()
  const ref = useRef<string>(asPath)

  const handleRouteChange = useCallback(() => {
    page({ previousUrl: ref.current })
    ref.current = asPath
  }, [asPath])

  /**
   * this was added as a temporary solution to trigger initial page view event
   * because on routeChangeComplete doesn't trigger this (because no change to the route...)
   */
  useEffect(() => {
    // This is so that we don't trigger double page tracking events redirecting
    // from /voucher/[code] to the homepage (ChrisG)
    if (pathname === '/voucher/[code]') return

    handleRouteChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // triggers events for subsequent route changes
  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange)
    return () => events.off('routeChangeComplete', handleRouteChange)
  }, [events, handleRouteChange])
}

const segmentCategorySchema = z.enum([
  'Analytics',
  'Advertising',
  'CRM',
  'Referrals',
])
type SegmentCategory = z.infer<typeof segmentCategorySchema>

/**
 * Maps segment's categories to our own consent categories
 */
const segmentCategoryToConsentCategoryMap = {
  Analytics: 'performance',
  Advertising: 'marketing',
  Referrals: 'marketing',
  CRM: 'performance',
} satisfies Record<SegmentCategory, ConsentCategory>

async function fetchSegmentDestinations(writeKey: string) {
  const response = await fetch(
    `https://analytics.qogita.com/v1/projects/${writeKey}/integrations`,
  )

  const responseBody = response.headers
    .get('content-type')
    ?.includes('application/json')
    ? await response.json()
    : await response.text()

  if (!response.ok) {
    throw new Error(
      `Failed to fetch segment integrations - ${response.status} - ${JSON.stringify(responseBody, null, 2)}`,
    )
  }

  return z
    .array(
      z.object({
        creationName: z.string(),
        category: segmentCategorySchema.transform(
          (value) => segmentCategoryToConsentCategoryMap[value],
        ),
      }),
    )
    .parse(responseBody)
}

function analyticsLoad({
  writeKey,
  externalIntegrations = {},
}: {
  writeKey: string
  externalIntegrations?: Record<string, boolean>
}) {
  return analytics.load(
    {
      writeKey,
      cdnURL: 'https://analytics.qogita.com',
    },
    {
      integrations: {
        All: false,
        'Segment.io': {
          apiHost: 'analyticsapi.qogita.com/v1',
        },
        ...externalIntegrations,
      },
    },
  )
}

function AnalyticsProvider({
  children,
  writeKey,
  isBot,
}: {
  children: ReactNode
  writeKey: string
  isBot: () => Promisable<boolean>
}) {
  const { consent } = useConsent()
  const isSegmentLoadedRef = useRef(false)

  useEffect(() => {
    async function loadSegment() {
      if (!writeKey) return
      // We don't want to track bot actions via Segment to prevent
      // event volumes skyrocketing and prevent bot actions skewing our analytics
      if (await isBot()) return

      // Don't load segment if we don't know the user's preferences yet
      if (consent.status === 'loading') return
      // Don't load segment at all if the user has opted out of analytics
      if (consent.value.performance === false) return
      // Don't load segment if we've already loaded it
      // If the user changes their preferences mid session we'll hard refresh
      if (isSegmentLoadedRef.current) return

      try {
        const destinations = await fetchSegmentDestinations(writeKey)
        const externalIntegrations: Record<string, boolean> = {}
        destinations.forEach((destination) => {
          let isEnabled = consent.value[destination.category]

          // We skip loading some destination scripts via Segment because we
          // manually load scripts and send events from the FE (usually for ad attribution)
          // but maintain their Segment destinations to map BE Segment events to the correct destination events.
          if (
            destination.creationName === 'Google Analytics 4 Web' ||
            destination.creationName === 'Google AdWords New' ||
            destination.creationName === 'Facebook Pixel' ||
            destination.creationName === 'Bing Ads'
          ) {
            isEnabled = false
          }

          externalIntegrations[destination.creationName] = isEnabled
        })

        analyticsLoad({ writeKey, externalIntegrations })
      } catch (error) {
        logError(error)
        // If we fail to fetch the integrations, we'll just load segment without them
        analyticsLoad({ writeKey })
      } finally {
        isSegmentLoadedRef.current = true
      }
    }

    loadSegment()
    // consent is an object so adding to dependency array is dangerous
    // This is why we have a lot of guarded early returns in loadSegment
  }, [consent, writeKey, isBot])

  return (
    <AnalyticsContext.Provider value={customAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

const pagesRouterIsBot = () => {
  return getIsBotUser({
    isBotDetectionEnabled: environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION,
  })
}

export function AnalyticsProviderPagesRouter({
  children,
  writeKey,
}: {
  children: ReactNode
  writeKey: string
}) {
  usePageTrackingPagesRouter()

  const canaryClient = useCanaryClient()
  const { isAuthenticated } = useAuthentication()
  const userQuery = useQuery({
    ...getUserQueries(canaryClient).detail(),
    enabled: isAuthenticated,
  })

  const user = userQuery.data ?? null

  useReidentifyUser({ user })

  return (
    <AnalyticsProvider writeKey={writeKey} isBot={pagesRouterIsBot}>
      {children}
    </AnalyticsProvider>
  )
}

export function AnalyticsProviderAppRouter({
  children,
  writeKey,
  userPromise,
}: {
  children: ReactNode
  writeKey: string
  userPromise: Promise<AnalyticsUser | null>
}) {
  const pathname = usePathname() ?? ''
  const searchParams = useSearchParams() ?? new URLSearchParams()
  const currentUrl = searchParams.size
    ? `${pathname}?${searchParams}`
    : pathname
  const previousUrlRef = useRef('')
  const botDetection = useBotDetection()

  const isBot = useCallback(() => {
    return botDetection === 'bot' || botDetection === 'loading'
  }, [botDetection])

  useEffect(() => {
    const previousUrl = previousUrlRef.current
    if (previousUrl !== currentUrl) {
      page({ previousUrl })
      previousUrlRef.current = currentUrl
    }
  }, [currentUrl])

  return (
    <AnalyticsProvider writeKey={writeKey} isBot={isBot}>
      {children}
      <Suspense fallback={null}>
        <ReidentifyUser userPromise={userPromise} />
      </Suspense>
    </AnalyticsProvider>
  )
}

function ReidentifyUser({
  userPromise,
}: {
  userPromise: Promise<AnalyticsUser | null>
}) {
  const user = use(userPromise)

  useReidentifyUser({ user })

  return null
}
