type GetNumberWithThousandsSeparator = (
  amount: number | string,
  precision?: number,
) => string
type GetDecimalAsPercentage = (
  amount: number | string,
  precision?: number,
) => string

/**
 * Format a number or numeric string to a locale-sensitive string.
 * @param amount A number or numeric string.
 * @param precision The number of digits to appear after the decimal point
 * after the value is converted to a number string with thousands separator(s).
 * When omited, no change will be applied to the formatted value.
 * @returns A locale-sensitive string of a given number.
 */
export const getNumberWithThousandsSeparator: GetNumberWithThousandsSeparator =
  (amount, precision) => {
    const stringifiedValue = amount.toString()
    const decimalDigits = stringifiedValue.split('.')[1]?.length

    const value = Number(amount) || 0
    return value.toLocaleString('en-GB', {
      minimumFractionDigits: precision ?? decimalDigits,
      maximumFractionDigits: precision ?? decimalDigits,
    })
  }

/**
 * Format a number or numeric string to a percentage value string.
 * @param amount A number or numeric string.
 * @param precision The number of digits to appear after the decimal point
 * after the value is converted to a percentage value. Default: 0.
 * @returns A percentage string if the given number is not 0, otherwise it will
 * return 0.
 */
export const getDecimalAsPercentage: GetDecimalAsPercentage = (
  amount,
  precision = 0,
) => {
  const value = Number(amount) * 100

  if (isNaN(value)) {
    throw new TypeError(`Invalid number: ${amount}`)
  }

  return `${value.toFixed(precision)}%`
}

export const getValueRoundedToDecimalPlaces = (
  value: number,
  decimalPlaces: number,
) => {
  const roundingFactor = Math.pow(10, decimalPlaces)
  return Math.round(value * roundingFactor) / roundingFactor
}

export const isNonZero = (value: number | string | null) =>
  Number(value) !== 0 && !isNaN(Number(value))
