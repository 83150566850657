import { logError } from '@qogita/logging/browser-logger'
import clsx from 'clsx'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ErrorBoundary } from 'react-error-boundary'
import { PageStoryblok } from 'src/core/cms/storyblok-component-types'
import { Footer } from 'src/deprecated/components/Footer'
import { Masthead } from 'src/deprecated/components/Masthead'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { DEFAULT_OPEN_GRAPH_IMAGE } from 'src/deprecated/lib/info'

type OpenGraph = {
  image?: {
    url: string
    width: string
    height: string
  }
}

export type LayoutProps = {
  padded?: boolean
  children: React.ReactNode
  title: string
  description: string
  pageBlok: PageStoryblok | null
  openGraph?: OpenGraph
  className?: string
}

export const LayoutHead = ({
  title,
  description,
  openGraph: openGraphOverrides,
}: {
  title: string
  description: string
  openGraph?: OpenGraph
}) => {
  const router = useRouter()

  const openGraphMeta = {
    siteName: 'Qogita',
    image: DEFAULT_OPEN_GRAPH_IMAGE,
    twitterCard: 'summary',
    title,
    description,
    url: `${environment.NEXT_PUBLIC_BASE_URL}${router.asPath}`,
    ...openGraphOverrides,
  }

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:site_name" content={openGraphMeta.siteName} />
      <meta property="og:title" content={openGraphMeta.title} key="og:title" />
      <meta
        property="og:description"
        content={openGraphMeta.description}
        key="og:description"
      />
      <meta property="og:url" content={openGraphMeta.url} />
      <meta
        property="og:image"
        content={openGraphMeta.image.url}
        key="og:image"
      />
      <meta
        property="og:image:width"
        content={openGraphMeta.image.width}
        key="og:image:width"
      />
      <meta
        property="og:image:height"
        content={openGraphMeta.image.height}
        key="og:image:height"
      />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content={openGraphMeta.twitterCard} />
    </Head>
  )
}

export const Layout = ({
  children,
  title,
  description,
  openGraph,
  padded: isPadded = true,
  pageBlok,
  className,
}: LayoutProps) => {
  return (
    <div className="flex min-h-screen flex-col">
      <LayoutHead
        title={title}
        description={description}
        openGraph={openGraph}
      />
      <ErrorBoundary
        FallbackComponent={() => null}
        onError={(error) => {
          logError(
            new Error(`Layout Masthead: ${error.message}`, { cause: error }),
          )
        }}
      >
        <Masthead />
      </ErrorBoundary>
      <div className={clsx('flex-grow', { 'py-2': isPadded }, className)}>
        {children}
      </div>
      <Footer pageBlok={pageBlok} />
    </div>
  )
}
