'use client'

import { trimmedString } from '@qogita/validation/string'
import Script from 'next/script'
import { useConsent } from 'src/core/consent'

const NEXT_PUBLIC_META_PIXEL_ID = trimmedString().parse(
  process.env.NEXT_PUBLIC_META_PIXEL_ID,
)

export function MetaScript() {
  const { hasConsent } = useConsent()

  if (!hasConsent('marketing')) {
    return null
  }

  return (
    <Script id="meta-pixel" strategy="afterInteractive">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${NEXT_PUBLIC_META_PIXEL_ID}');
        fbq('track', 'PageView');
      `}
    </Script>
  )
}
