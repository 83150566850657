import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'
import { ImageStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'

import { storyblokImageLoader } from './storyblokImageLoader'

const ImageBlokComponent = ({ blok }: { blok: ImageStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Image
        loader={storyblokImageLoader}
        src={blok.asset.filename}
        alt={blok.asset.alt ?? ''}
        className="w-full rounded-lg"
        width={1024}
        height={1024}
        layout="responsive"
      />
    </div>
  )
}
export const ImageBlok = ({ blok }: { blok: ImageStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <ImageBlokComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
